import config from '../config.json';

export const coreService = {
  setItem: (key, value) => {
    localStorage.setItem(key, value);
  },
  getItem: (key) => {
    return localStorage.getItem(key);
  },
  removeItem: (key) => {
    localStorage.removeItem(key);
  },
  getConfig: (key) => {
    if (key) return config[key] || false;
    return config || false;
  },
  setObjectItem: (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
  },
  getObjectItem: (key) => {
    return JSON.parse(localStorage.getItem(key));
  },
  isEmptyObject: (value) => {
    if (typeof value !== 'object') {
      return false;
    }
    const param = value || {};
    return Object.keys(param).length === 0;
  },
  isEmptyValue: (value) => {
    return [undefined, null, ''].indexOf(value) !== -1;
  },
  scrollUp: () => {
    window.scrollTo(0, 0);
  },
  checkAuthorization: (err) => {
    if (err?.response?.status === 401) {
      coreService.removeItem('isLoggedIn');
      coreService.removeItem('accessToken');
      window.location.replace('/auth/login');
      return;
    }
    return err
  },
  getOS: (customWindowObj) => {
    let userAgent = (customWindowObj || window).navigator.userAgent,
      platform = (customWindowObj || window).navigator.platform,
      macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
      windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
      iosPlatforms = ['iPhone', 'iPad', 'iPod'],
      os = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
      os = 'Mac OS';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = 'iOS';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = 'Windows';
    } else if (/Android/.test(userAgent)) {
      os = 'Android';
    } else if (!os && /Linux/.test(platform)) {
      os = 'Linux';
    } else if (/CriOS/.test(userAgent)) {
      os = 'iOS';
    } else if (/FxiOS/.test(userAgent)) {
      os = 'iOS';
    }

    return os;
  },
  getBrowser: (customNavigatorObj) => {
    if (((customNavigatorObj || navigator).userAgent.indexOf("Opera") !== -1 || (customNavigatorObj || navigator).userAgent.indexOf('OPR')) !== -1) {
      return 'Opera';
    } else if ((customNavigatorObj || navigator).userAgent.indexOf("Chrome") !== -1) {
      return 'Chrome';
    } else if ((customNavigatorObj || navigator).userAgent.indexOf("Safari") !== -1) {
      return 'Safari';
    } else if ((customNavigatorObj || navigator).userAgent.indexOf("Firefox") !== -1) {
      return 'Firefox';
    } else if (((customNavigatorObj || navigator).userAgent.indexOf("MSIE") !== -1) || (!!document.documentMode === true)) {
      return 'IE';
    } else {
      return 'Unknown';
    }
  },
  b64toBlob: (b64Data, contentType, sliceSize, fileName) => {
    contentType = contentType || "";
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    return new File(byteArrays,
      contentType.includes('image') ? `${fileName || 'image'}.png` : `${fileName || 'file'}.pdf`,
      { type: contentType });
  },
  scrollToErrorElement: (containerRef, selectors = ['.MuiFormHelperText-root.Mui-error', '.MuiAlert-root.MuiAlert-standardError', '.MuiFormHelperText-root.Mui-error']) => {
    if (!containerRef.current) {
      return;
    }

    setTimeout(() => {
      for (let i = 0; i < selectors.length; i++) {
        const selector = selectors[i];
        const foundErrorElement = containerRef.current.querySelector(selector);
        if (foundErrorElement && typeof foundErrorElement.scrollIntoView === 'function') {
          foundErrorElement.scrollIntoView();
          window.scrollBy({ behavior: 'smooth', top: -100 });
          break;
        }
      }
    }, 100);
  },
  resizeImageCalc: (img) => {
    const appConfig = coreService.getObjectItem('appConfig')
    const MAX_WIDTH = Number(appConfig?.cfg_img?.img_w)
    const MAX_HEIGHT = Number(appConfig?.cfg_img?.img_h)
    let width = img.width;
    let height = img.height;
    // Change the resizing logic
    if (width > height) {
      if (width > MAX_WIDTH) {
        height = height * (MAX_WIDTH / width);
        width = MAX_WIDTH;
      }
    } else {
      if (height > MAX_HEIGHT) {
        width = width * (MAX_HEIGHT / height);
        height = MAX_HEIGHT;
      }
    }
    return { width, height }
  }
}