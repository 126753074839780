import React, {useCallback, useState} from 'react'
import {useDropzone} from 'react-dropzone'
import Alert from '@mui/material/Alert';
import { Viewer } from '@react-pdf-viewer/core';
import './inputs.css'
import { coreService } from '../../core/service';

function FormUploadFile({onChange, options, testId, customForms, disabled}) {
  const [uploadedFiles, setUploadedFiles] = useState([])
  const [errors, setErrors] = useState([])

  const reset = () => {
    setUploadedFiles([])
    setErrors([])
  }

  React.useEffect(() => {
    if (!customForms || coreService.isEmptyObject(customForms)) {
      return
    }

    if (!customForms[testId]) {
      return
    }
    setUploadedFiles(customForms[testId])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customForms])

  const onDrop = useCallback((acceptedFiles) => {
    reset()
    let tempUploadedFiles = []
    acceptedFiles.forEach((file) => {
      const reader = new FileReader()
      const img = new Image()

      reader.onabort = () => console.log('file reading was aborted')
      reader.onerror = () => console.log('file reading has failed')
      reader.onload = () => {
        // Do whatever you want with the file contents
        let convertedResult = reader.result
        if (file?.type?.includes('image')) {
          img.onload = () => {
            const { width, height } = coreService.resizeImageCalc(img)
            let canvas = document.createElement("canvas");
            canvas.width = width;
            canvas.height = height;
            let ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0, width, height);
            convertedResult = canvas.toDataURL(file?.type || 'image/png')
            tempUploadedFiles.push({ file: convertedResult, type: file?.type })
            setUploadedFiles(tempUploadedFiles)
            onChange(tempUploadedFiles)
          }
          img.src = URL.createObjectURL(file)
          return
        }
        tempUploadedFiles.push({file: convertedResult, type: file?.type})
        setUploadedFiles(tempUploadedFiles)
        onChange(tempUploadedFiles)
      }
      if (file?.type?.includes('pdf')) {
        reader.readAsDataURL(file)
      } else {
        reader.readAsArrayBuffer(file)
      }
    })
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onDropRejected = useCallback((e) => {
    reset()
    if (e?.length === 0) {
      return
    }

    let errorMsg = []
    for (let j = 0; j < e?.length; j += 1) {
      for (let i = 0; i < e[j]?.errors?.length; i += 1) {
        let message = e[j]?.errors[i]?.message;
        if (e[j]?.errors[i]?.code === 'file-too-large') {
          message = `file terlalu besar dari ukuran ${options?.maxSize} bytes`
        }
        if (e[j]?.errors[i]?.code === 'file-too-small') {
          message = `file terlalu kecil dari ukuran ${options?.minSize} bytes`
        }
        if (e[j]?.errors[i]?.code === 'too-many-files') {
          message = `maksimal file yang diizinkan hanya ${options?.maxFiles} file`
        }
        errorMsg.push({ msg: message, fileName: e[j]?.file?.name })
        setErrors(errorMsg)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const {
    getRootProps,
    getInputProps,
  } = useDropzone({ onDrop, ...options, onDropRejected })

  return (
    <div style={{ marginTop: '10px' }}>
      {!disabled ?
        <div {...getRootProps()} className={'button-2'}>
          <input data-testid={`dropzone-${testId || 'test-id'}`} {...getInputProps()} />
          <p style={{ marginTop: '5px' }}>UPLOAD</p>
        </div> :
        <div className={'button-disabled'}>
          <p style={{ marginTop: '5px' }}>UPLOAD</p>
        </div>
      }
      {errors?.length > 0 &&
        <div>
          {errors?.map((error, i) => (
            <Alert severity="error" key={i} sx={{ mt: 1 }}>{`${error?.msg} - ${error?.fileName}`}</Alert>
          ))}
        </div>
      }
      {uploadedFiles?.length > 0 &&
        <div style={{ display: 'flex', padding: '16px' }}>
          {uploadedFiles?.map((file, i) => (
            <React.Fragment key={i}>
              {file?.type?.includes('image') &&
                <>
                  <img src={file?.file} alt={i} height={'250px'} style={{ maxWidth: '100%' }} />
                </>
              }
              {file?.type?.includes('pdf') &&
                <div className='pdf-view'>
                  <Viewer fileUrl={file?.file} />
                </div>
              }
            </React.Fragment>
          ))}
        </div>
      }
    </div>
  )
}

export default FormUploadFile