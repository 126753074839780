import * as React from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Skeleton, Box, Container, Paper, Stepper, Step, StepButton, Button, Typography, StepLabel, Snackbar, Alert, CircularProgress, Backdrop } from '@mui/material';

import Introduction from './Introduction';
import DataDiriForm from './DataDiriForm';
import DataLahanForm from './DataLahanForm';
import DataKomoditasForm from './DataKomoditasForm';
import DataDaerahForm from './DataDaerahForm';
import DataLainnyaForm from './DataLainnyaForm';
import { coreService } from '../../core/service';
import AlertDialog from '../../components/utils/AlertDialog';
import questionnaireFormBuilder from './questionnaireFormBuilder';
import { questionnaireApi } from '../../apis';
import kitaniLogo from '../../assets/brand/kitani.png';
import AnalyticsEventTracker from '../../hooks/useAnalyticsEventTracker';
import ReCAPTCHA from 'react-google-recaptcha';

const steps = ['Introduction', 'Data Diri', 'Data Lahan', 'Data Komoditas', 'Data Daerah', 'Data Lainnya.'];
const stepNameMap = {
  0: null,
  1: 'personal',
  2: 'land',
  3: 'commodity',
  4: 'area_info',
  5: 'others',
};
const LeadQuestionnaire = () => {
  const analytics = AnalyticsEventTracker('Lead Questionnaire');
  const [isReady, setIsReady] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [validationSchemas, setValidationSchemas] = React.useState({});
  const [activeValidation, setActiveValidation] = React.useState(yup.object());
  const [configs, setConfigs] = React.useState({});

  const formik = useFormik({
    initialValues: {},
    validationSchema: activeValidation
  });

  // Save values everytime an input is blurred
  const formikHandleBlur = formik.handleBlur;
  formik.handleBlur = (...rest) => {
    formikHandleBlur.apply(this, rest);
    coreService.setObjectItem('questionnaire:lastValues', formik.values);
  };

  const [showConfirm, setShowConfirm] = React.useState(false);
  const [showAllErrors, setShowAllErrors] = React.useState(false);

  const containerRef = React.useRef();
  const handleNext = () => {
    formik.validateForm().then(res => {
      if (Object.keys(res?.[stepNameMap[activeStep]] || {}).length) {
        setShowAllErrors(true);
        coreService.scrollToErrorElement(containerRef);
        analytics?.eventTracker('Click', `Failed next to ${steps[activeStep + 1]} page`);
        return;
      }

      if (activeStep === steps.length - 1) {
        setShowConfirm(true);
        return;
      }

      coreService.scrollUp();
      formik.setTouched({});
      setShowAllErrors(false);
      setActiveStep(activeStep + 1);
      coreService.setItem('questionnaire:lastActiveStep', activeStep + 1);
      analytics?.eventTracker('Click', `Next to ${steps[activeStep + 1]} page`);
    });
  };

  const handleBack = () => {
    coreService.scrollUp();
    formik.setTouched({});
    setShowAllErrors(false);
    setActiveStep(activeStep - 1);
    coreService.setItem('questionnaire:lastActiveStep', activeStep - 1);
    analytics?.eventTracker('Click', `Back to ${steps[activeStep - 1]} page`);
  };

  const [isPreparing, setIsPreparing] = React.useState(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const prepareFormConfigurations = () => {
    if (isReady || isPreparing) {
      return;
    }
    setIsPreparing(true);
    questionnaireFormBuilder()
      .buildForm()
      .then(({ validationSchema, formBuilder, configs }) => {
        setValidationSchemas(validationSchema);
        formik.setValues(formBuilder);
        setConfigs(configs);
        setIsReady(true);
      })
      .finally(() => {
        setIsPreparing(false);
      });
  };

  React.useEffect(() => {
    if (!isReady) {
      return;
    }
    let lastActiveStep = coreService.getItem('questionnaire:lastActiveStep');
    if (!coreService.isEmptyValue(lastActiveStep)) {
      lastActiveStep = Number(lastActiveStep);
      setActiveStep(lastActiveStep);
    }
  }, [isReady]);

  // Only activate validation for active form
  React.useEffect(() => {
    setActiveValidation(activeValidation.shape({
      [stepNameMap[activeStep]]: validationSchemas[stepNameMap[activeStep]] || null
    }));

    // reset data when reach finish
    if (activeStep === steps.length) {
      coreService.removeItem('questionnaire:lastActiveStep');
      coreService.removeItem('questionnaire:lastValues');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep, validationSchemas]);

  function getStepContent(step) {
    switch (step) {
    case 0:
      return <Introduction />;
    case 1:
      return <DataDiriForm formData={formik} showAllErrors={showAllErrors} snackbar={snackbar} setSnackbar={setSnackbar}/>;
    case 2:
      return <DataLahanForm formData={formik} showAllErrors={showAllErrors} />;
    case 3:
      return <DataKomoditasForm formData={formik} showAllErrors={showAllErrors} configs={configs} />;
    case 4:
      return <DataDaerahForm formData={formik} showAllErrors={showAllErrors} />;
    case 5:
      return <DataLainnyaForm formData={formik} showAllErrors={showAllErrors} configs={configs} />;

    default:
      throw new Error('Unknown step');
    }
  }

  const [captchaSuccess, setCaptchaSuccess] = React.useState(false);
  function onCaptchaSuccess() {
    setCaptchaSuccess(true);
  }
  const [snackbar, setSnackbar] = React.useState({ severity: 'error', message: '', open: false });
  const handleCloseSnackbar = () => setSnackbar({ ...snackbar, open: false });

  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const submitForm = () => {
    const formPayload = { ...formik.values };

    setIsSubmitting(true);
    analytics?.eventTracker('Click', `Submit questionnaire`);
    formPayload.personal = { ...formik.values.personal };
    formPayload.personal['msisdn'] = '62' + formPayload.personal['msisdn'];
    questionnaireApi.submitForm(formPayload)
      .then((res) => {
        setActiveStep(activeStep + 1); onCaptchaSuccess(false);
      }).catch(e => {
        const errorMessage = e?.response?.data?.message || e?.message || 'Maaf terjadi kesalahan.'; setSnackbar({ ...snackbar, open: true, message: errorMessage });
      }).finally(() => { setIsSubmitting(false); });
  };

  React.useEffect(() => {
    analytics?.pageViewTracker();
    prepareFormConfigurations();
    // eslint-disable-next-line
  }, []);

  return (
    <Container ref={containerRef} component="main" maxWidth="sm" sx={{ mb: 4 }}>
      <AlertDialog
        content={'Pastikan agar kami dapat dengan mudah menilai dan menghubungi anda'}
        isShown={showConfirm}
        toggleFn={setShowConfirm}
        title={'Apakah data sudah benar?'}
        yesLabel={'Ya'}
        noLabel={'Tidak'}
        callbackFn={() => {
          submitForm();
        }}
      />

      <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <img style={{ maxHeight: '75px', maxWidth: '175px', height: '100%', width: '100%' }} src={kitaniLogo} alt='kitani-header' />
        </Box>
        {activeStep < steps.length &&
          <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
            {steps.map((label, index) => (
              <Step key={label} completed={activeStep > index}>
                {!isReady ?
                  <StepLabel StepIconComponent={() => (<Skeleton width={30} animation='wave' height={30} variant='circular' />)}>{ }</StepLabel>
                  : <StepButton
                    onClick={() => {
                      analytics?.eventTracker('Click', `Jump to ${steps[index]} page`);
                      setActiveStep(index);
                    }}
                    color="inherit">{ }</StepButton>
                }
              </Step>
            ))}
          </Stepper>}
        <React.Fragment>
          {activeStep === steps.length ? (
            <React.Fragment>
              <Typography sx={{ marginTop: '1rem' }} variant="subtitle1">
                Terimakasih sudah mengirimkan data dan informasi yang diperlukan, tim Kitani akan segera menghubungi Anda.
              </Typography>
            </React.Fragment>
          ) : isReady ? (
            <React.Fragment>
              {getStepContent(activeStep)}
              {(activeStep === steps.length - 1) && (<Box sx={{ display: 'flex', justifyContent: 'end' }}>
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
                  onChange={onCaptchaSuccess}
                />
              </Box>)}
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                {activeStep !== 0 && (
                  <Button
                    disabled={!isReady}
                    onClick={handleBack}
                    sx={{ mt: 3, ml: 1 }}>
                    Kembali
                  </Button>
                )}

                <Button
                  disabled={!isReady || (!captchaSuccess && activeStep === steps.length - 1)}
                  variant="contained"
                  onClick={handleNext}
                  sx={{ mt: 3, ml: 1 }}
                >
                  {activeStep === steps.length - 1 ? 'Kirim' : activeStep === 0 ? 'Mulai' : 'Lanjutkan'}
                </Button>
              </Box>
            </React.Fragment>
          ) :
            (<>
              <Skeleton animation="wave" width="100%" height={30} />
              <Skeleton animation="wave" width="100%" height={50} />
              <Skeleton animation="wave" width="100%" height={30} />
              <Skeleton animation="wave" width="100%" height={50} />
            </>)}
        </React.Fragment>
      </Paper>

      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity || 'error'} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isSubmitting}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
}

export default LeadQuestionnaire;