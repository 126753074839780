import * as React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import FormRadioButton from '../../components/inputs/FormRadioButton';

const petaniLainOptions = [{
  value: '0',
  label: 'Tidak Ada'
}, {
  value: '1-20 orang',
  label: '1-20 Orang'
}, {
  value: '>20 orang',
  label: 'Lebih dari 20 Orang'
}];
const potensiLahanOptions = [{
  value: '<10 Ha',
  label: 'Kurang dari 10 Hektar'
}, {
  value: '10-50 Ha',
  label: '10-50 Hektar'
}, {
  value: '>50 Ha',
  label: 'Lebih dari 50 Hektar'
}];

export default function DataDaerahForm({ formData, showAllErrors, pageKey = 'area_info' }) {
  // eslint-disable-next-line
  const formValue = formData.values[pageKey],
    formTouched = formData.touched[pageKey] || {},
    formErrors = formData.errors[pageKey] || {},
    qty_farmer = 'qty_farmer',
    land_potential = 'land_potential';
  const firstElementRef = React.useRef(null);
  React.useEffect(() => {
    firstElementRef.current.focus();
  }, []);
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Data Daerah
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FormRadioButton
            inputRef={firstElementRef}
            value={formData.values[pageKey][qty_farmer]}
            name={`${pageKey}.${qty_farmer}`}
            onChange={formData.handleChange}
            onBlur={formData.onBlur}
            error={(formTouched[qty_farmer] || showAllErrors) && Boolean(formErrors[qty_farmer])}
            helperText={(formTouched[qty_farmer] || showAllErrors) && formErrors[qty_farmer]}
            label="Ada berapa banyak petani lain di daerah anda yang juga menanam komoditas?"
            options={petaniLainOptions} />
        </Grid>
        <Grid item xs={12}>
          <FormRadioButton
            value={formData.values[pageKey][land_potential]}
            name={`${pageKey}.${land_potential}`}
            onChange={formData.handleChange}
            onBlur={formData.onBlur}
            error={(formTouched[land_potential] || showAllErrors) && Boolean(formErrors[land_potential])}
            helperText={(formTouched[land_potential] || showAllErrors) && formErrors[land_potential]}
            label="Berapa luas potensi lahan yang bisa ditanami di daerah anda (m2) ?"
            options={potensiLahanOptions} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
