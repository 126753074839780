import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';

function FormDropdown(props) {
  const { 
    label,
    name, 
    options = [{ value: 'Please select' }],
    value, 
    onChange, 
    helperText, 
    error,
    onBlur,
    inputRef,
    disabled
  } = props;
  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth error={error}>
        <InputLabel id={`${name}-label`}>{label}</InputLabel>
        <Select
          inputRef={inputRef}
          disabled={disabled}
          labelId={`${name}-label`}
          id={`${name}-select`}
          value={value}
          label={label}
          onChange={onChange}
          name={name}
          onBlur={onBlur}
        >
          {options.map(({ value: optValue, label: optLabel }) => (
            <MenuItem key={optValue} value={optValue}>{optLabel || optValue}</MenuItem>
          ))}
        </Select>
        <FormHelperText>{helperText}</FormHelperText>
      </FormControl>
    </Box>
  );
}

export default FormDropdown;