import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import FormUploadFile from '../../components/inputs/FormUploadFile';
import FormRadioButton from '../../components/inputs/FormRadioButton';
import FormDatePicker from '../../components/inputs/FormDatePicker';
import FormAutoComplete from '../../components/inputs/FormAutoComplete';
import banks from './data-bank.json'
import { kitaniApi } from '../../apis';
import { Alert, Box, FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material';
import DomicileLocation from './domicile-location';

export default function DataDiriForm({ 
  formData, showAllErrors, pageKey='personal', updateCustomForms, customForms,
  errorCustomForms ,
  snackbar,
  setSnackbar
}) {
  // Store field name string into variable
  const formValue = formData.values[pageKey],
    formTouched = formData.touched[pageKey] || {},
    formErrors = formData.errors[pageKey] || {},
    full_name = 'full_name',
    msisdn = 'msisdn',
    gender = 'gender',
    pob = 'pob',
    idcard_no = 'idcard_no',
    street = 'street',
    province = 'province',
    city = 'city',
    district = 'district',
    village = 'village',
    po_code = 'po_code',
    tax_no = 'tax_no',
    bank_name = 'bank_name',
    bank_account_no = 'bank_account_no',
    dom_flag = 'dom_flag',
    dom_street = 'dom_street',
    dom_po_code = 'dom_po_code',
    bank_branch_city = 'bank_branch_city';

  const [isLoadingArea, setIsLoadingArea] = React.useState(false);
  const [provOptions, setProvOptions] = React.useState([]);
  const areaFieldNames = [
    `${pageKey}.${province}`,
    `${pageKey}.${city}`,
    `${pageKey}.${district}`,
    `${pageKey}.${village}`
  ];

  const resetAreaFields = (fields = []) => {
    fields.forEach(field => {
      formData.setFieldValue(field, '')
      formData.setFieldValue(field + '_code', '')
    });
  };

  React.useEffect(() => {
    setIsLoadingArea(true);
    kitaniApi.get(`/location/area?at=p`).then(res => {
      const { data: { data } } = res;
      const options = data.map(item => ({
        value: item.ac,
        label: item.an
      }));
      setProvOptions(options);
    }).catch(e => {
      setSnackbar({ ...snackbar, open: true, message: "Gagal Mendapatkan Data Provinsi" });      
    }).finally(() => setIsLoadingArea(false));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [cityOptions, setCityOptions] = React.useState([]);
  
  React.useEffect(() => {
    async function init() {
      if (!formValue[province]) {
        return;
      }
      setIsLoadingArea(true);
      if (!formValue[province + '_code']) {
        await kitaniApi.get(`/location/area?at=p`).then(res => {
          const { data: { data } } = res;
          const selectedProvince = data.find((item) => item.an?.includes(formValue[province]))
          formValue[province + '_code'] = selectedProvince?.ac
          const options = data.map(item => ({
            value: item.ac,
            label: item.an
          }));
          setProvOptions(options);
        })
      }
      kitaniApi.get(`/location/area?at=c&pac=${formValue[province + '_code']}`).then(res => {
        const { data: { data } } = res;
        const options = data.map(item => ({
          value: item.ac,
          label: item.an
        }));
        setCityOptions(options);
      }).catch(e => {
        setSnackbar({ ...snackbar, open: true, message: "Gagal Mendapatkan Data Kota" });      
      }).finally(() => setIsLoadingArea(false));
    }
    init()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValue[province]]);

  const [districtOptions, setDistrictOptions] = React.useState([]);
  React.useEffect(() => {
    async function init() {
      if (!formValue[city]) {
        return;
      }
      setIsLoadingArea(true);
      if (!formValue[city + '_code']) {
        await kitaniApi.get(`/location/area?at=c&pan=${formValue[province]}`).then(res => {
          const { data: { data } } = res;
          const selectedCity = data.find((item) => item.an?.includes(formValue[city]))
          formValue[city + '_code'] = selectedCity?.ac
        })
      }
      kitaniApi.get(`/location/area?at=d&pac=${formValue[city + '_code']}`).then(res => {
        const { data: { data } } = res;
        const options = data.map(item => ({
          value: item.ac,
          label: item.an
        }));
        setDistrictOptions(options);
      }).catch(e => {
        setSnackbar({ ...snackbar, open: true, message: "Gagal Mendapatkan Data Kecamatan" });      
      }).finally(() => setIsLoadingArea(false));
    }
    init()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValue[city]]);

  const [villageOptions, setVillageOptions] = React.useState([]);

  React.useEffect(() => {
    async function init() {
      if (!formValue[district]) {
        return;
      }
      setIsLoadingArea(true);
      if (!formValue[district + '_code']) {
        await kitaniApi.get(`/location/area?at=d&pan=${formValue[city]}`).then(res => {
          const { data: { data } } = res;
          const selectedDistrict = data.find((item) => item.an?.includes(formValue[district]))
          formValue[district + '_code'] = selectedDistrict?.ac
        })
      }
      kitaniApi.get(`/location/area?at=v&pac=${formValue[district + '_code']}`).then(res => {
        const { data: { data } } = res;
        const options = data.map(item => ({
          value: item.ac,
          label: item.an
        }));
        const selectedVillage = data.find((item) => item.an?.includes(formValue[village]))
        formValue[village + '_code'] = selectedVillage?.ac
        setVillageOptions(options);
      }).catch(e => {
        setSnackbar({ ...snackbar, open: true, message: "Gagal Mendapatkan Data Kelurahan" });      
      }).finally(() => setIsLoadingArea(false));
    }
    init()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValue[district]]);

  const [isTempatLahirLoading, setTempatLahirLoading] = React.useState(false);
  const [tempatLahirList, setTempatLahirList] = React.useState([]);

  React.useEffect(() => {
    setTempatLahirLoading(true);
    kitaniApi.get(`/location/area?at=c`).then(res => {
      const { data: { data } } = res;
      let list = []
      data.forEach((item) => {
        const existingCity = list.find((city) => city.an === item.an)
        if (!existingCity) {
          list.push(item)
        }
      })
      const options = list.map(item => ({
        value: item.ac,
        label: item.an
      }));
      setTempatLahirList(options);
    }).catch(e => {
      setSnackbar({ ...snackbar, open: true, message: "Gagal Mendapatkan Data Tempat Lahir" });      
    }).finally(() => setTempatLahirLoading(false));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  

  return (
    <React.Fragment>
      <Typography variant="h5" gutterBottom>
        Data Diri
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h7" gutterBottom>
            {'Nama Lengkap'}
          </Typography>
          <Typography variant="subtitle1" gutterBottom component="div">
            {formValue[full_name]}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="h7" gutterBottom>
            {'Nomor Whatsapp'}
          </Typography>
          <Typography variant="subtitle1" gutterBottom component="div">
            {formValue[msisdn]}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{display: 'flex', flexDirection: 'column'}}>
            <Typography variant="h6" gutterBottom>
                Foto Diri*
            </Typography>
            <Typography variant="h8" gutterBottom>
                (Foto pemilik identitas, tidak boleh terpotong, ukuran gambar tidak lebih dari 3Mb)
            </Typography>
          </Box>
          <FormUploadFile
            customForms={customForms}
            testId={'farmer_img'}
            options={{
              accept: {
                'image/*': []
              },
              maxFiles: 1,
              maxSize: 1000000 * 3, // 3mb
              minSize: 100000 // 100 kb
            }} 
            onChange={(newValue) => {
              updateCustomForms('farmer_img', newValue)
            }}
          />
          {errorCustomForms[0]?.['farmer_img'] && !customForms['farmer_img'] &&
          <Typography variant="subtitle2" gutterBottom component="div" style={{color: 'red'}}>
            <Alert severity="error" sx={{mt: 2}}>{'data kosong'}</Alert>
          </Typography>
          }
        </Grid>
        <Grid item xs={12}>
          <FormRadioButton 
            name={`${pageKey}.${gender}`}
            value={formValue[gender]}
            onChange={formData.handleChange}
            onBlur={formData.handleBlur}
            error={(formTouched[gender] || showAllErrors) && Boolean(formErrors[gender])}
            helperText={(formTouched[gender] || showAllErrors) && formErrors[gender]} 
            label="Jenis Kelamin"
            options={[{value: "Laki-Laki", label: "Laki - Laki"}, {value: "Perempuan", label: "Perempuan"}]}
            formName="gender" />
        </Grid>
        <Grid item xs={12}>
          <FormAutoComplete
            disabled={isTempatLahirLoading}
            value={formValue[pob + '_code']}
            defaultValue={formValue[pob]}
            onChange={(name, val) => {
              formData.setFieldValue(`${pageKey}.${pob}`, val.label);
            }}
            name={`${pageKey}.${pob}_code`}
            setFieldValue={formData.setFieldValue}
            onBlur={formData.handleBlur}
            error={(formTouched[pob] || showAllErrors) && Boolean(formErrors[pob])}
            helperText={(formTouched[pob] || showAllErrors) && formErrors[pob]}
            label="Tempat Lahir"
            options={tempatLahirList}
          />
        </Grid>
        <Grid item xs={12}>
          <FormDatePicker 
            formName="dob" label="Tanggal lahir"
            value={customForms['dob']}
            inputFormat={"dd/MM/yyyy"}
            error={false}
            onChange={(newValue) => {
              updateCustomForms('dob', newValue)
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Box sx={{display: 'flex', flexDirection: 'column'}}>
            <Typography variant="h6" gutterBottom>
               KTP
            </Typography>
            <Typography variant="h8" gutterBottom>
                (Foto KTP yang masih berlaku, foto tidak boleh terpotong, ukuran gambar tidak boleh lebih dari 3Mb)
            </Typography>
          </Box>
          <FormUploadFile
            customForms={customForms}
            testId={'idcard_img'}
            options={{
              accept: {
                'image/*': []
              },
              maxFiles: 1,
              maxSize: 1000000 * 3, // 3mb
              minSize: 100000 // 100 kb
            }} 
            onChange={(newValue) => {
              updateCustomForms('idcard_img', newValue)
            }}
          />
          {errorCustomForms[0]?.['idcard_img'] && !customForms['idcard_img'] && 
          <Typography variant="subtitle2" gutterBottom component="div" style={{color: 'red'}}>
            <Alert severity="error" sx={{mt: 2}}>{'data kosong'}</Alert>
          </Typography>
          }
        </Grid>
        <Grid item xs={12}>
          <TextField
            value={formValue[idcard_no]}
            onChange={formData.handleChange}
            onBlur={formData.handleBlur}
            error={(formTouched[idcard_no] || showAllErrors) && Boolean(formErrors[idcard_no])}
            helperText={(formTouched[idcard_no] || showAllErrors) && formErrors[idcard_no]}
            required
            id={idcard_no}
            name={`${pageKey}.${idcard_no}`}
            label="Nomor KTP"
            type="text"
            fullWidth
            autoComplete="nik-ktp"
            variant="standard"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            value={formValue[street]}
            onChange={formData.handleChange}
            onBlur={formData.handleBlur}
            error={(formTouched[street] || showAllErrors) && Boolean(formErrors[street])}
            helperText={(formTouched[street] || showAllErrors) && formErrors[street]}
            required
            id={street}
            label="Alamat Sesuai KTP"
            name={`${pageKey}.${street}`}
            fullWidth
            autoComplete="alamat-ktp"
            variant="standard"
            sx={{marginBottom: '1rem'}}
          />
        </Grid>
        
        <Grid item xs={12}>
          <FormAutoComplete
            disabled={isLoadingArea} 
            label="Provinsi"
            options={provOptions}
            value={formValue[province + '_code']}
            defaultValue={formValue[province]}
            onChange={(name, val) => {
              resetAreaFields(areaFieldNames.slice(1));
              formData.setFieldValue(`${pageKey}.${province}`, val.label);
            }}
            name={`${pageKey}.${province}_code`}
            setFieldValue={formData.setFieldValue}
            onBlur={formData.handleBlur}
            error={(formTouched[province] || showAllErrors) && Boolean(formErrors[province])}
            helperText={(formTouched[province] || showAllErrors) && formErrors[province]}
          />
        </Grid>
        <Grid item xs={12}>
          <FormAutoComplete
            disabled={isLoadingArea || !formValue[province]} 
            label="Kabupaten/Kota"
            options={cityOptions}
            value={formValue[city + '_code']}
            name={`${pageKey}.${city}_code`}
            defaultValue={formValue[city]}
            onChange={(name, val) => {
              resetAreaFields(areaFieldNames.slice(2));
              formData.setFieldValue(`${pageKey}.${city}`, val.label);
            }}
            setFieldValue={formData.setFieldValue}
            onBlur={formData.handleBlur}
            error={(formTouched[city] || showAllErrors) && Boolean(formErrors[city])}
            helperText={(formTouched[city] || showAllErrors) && formErrors[city]}
          />
        </Grid>
        <Grid item xs={12}>
          <FormAutoComplete
            disabled={isLoadingArea || !formValue[city]} 
            label="Kecamatan"
            options={districtOptions}
            value={formValue[district + '_code']}
            defaultValue={formValue[district]}
            name={`${pageKey}.${district}_code`}
            onChange={(name, val) => {
              resetAreaFields(areaFieldNames.slice(3));
              formData.setFieldValue(`${pageKey}.${district}`, val.label);
            }}
            setFieldValue={formData.setFieldValue}
            onBlur={formData.handleBlur}
            error={(formTouched[district] || showAllErrors) && Boolean(formErrors[district])}
            helperText={(formTouched[district] || showAllErrors) && formErrors[district]}
          />
        </Grid>
        <Grid item xs={12}>
          <FormAutoComplete
            disabled={isLoadingArea || !formValue[district]} 
            label="Kelurahan/Desa"
            options={villageOptions}
            value={formValue[village + '_code']}
            defaultValue={formValue[village]}
            name={`${pageKey}.${village}_code`}
            onChange={(name, val) => {
              formData.setFieldValue(`${pageKey}.${village}`, val.label);
            }}
            setFieldValue={formData.setFieldValue}
            onBlur={formData.handleBlur}
            error={(formTouched[village] || showAllErrors) && Boolean(formErrors[village])}
            helperText={(formTouched[village] || showAllErrors) && formErrors[village]}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Kode Pos"
            type="text"
            fullWidth
            variant="standard"
            value={formValue[po_code]}
            onChange={formData.handleChange}
            onBlur={formData.handleBlur}
            error={(formTouched[po_code] || showAllErrors) && Boolean(formErrors[po_code])}
            helperText={(formTouched[po_code] || showAllErrors) && formErrors[po_code]}
            required
            id={po_code}
            name={`${pageKey}.${po_code}`}
          />
        </Grid>

        <Grid item xs={12}>
          <Box sx={{ minWidth: 120, marginTop: '2rem' }}>
            <FormControl fullWidth error={(formTouched[dom_flag] || showAllErrors) && Boolean(formErrors[dom_flag])}>
              <InputLabel id={dom_flag + '-label'}>Apakah alamat domisili berbeda dengan alamat di KTP ?</InputLabel>
              <Select
                name={`${pageKey}.dom_flag`}
                value={formValue?.[dom_flag]}
                onChange={formData.handleChange}
                onBlur={formData.handleBlur}
                labelId={dom_flag + '-label'}
                id={dom_flag}
                label="Apakah alamat domisili berbeda dengan alamat di KTP ?"
              >
                <MenuItem value={0}>berbeda dengan KTP</MenuItem>
                <MenuItem value={1}>sama dengan KTP</MenuItem>
              </Select>
              <FormHelperText>{(formTouched[dom_flag] || showAllErrors) && formErrors[dom_flag]}</FormHelperText>
            </FormControl>
          </Box>
        </Grid>
        
        {[1, '1', '', undefined, null, false].indexOf(formValue[dom_flag]) === -1 && <>
          <Grid item xs={12}>
            <TextField
              value={formValue[dom_street]}
              onChange={formData.handleChange}
              onBlur={formData.handleBlur}
              error={(formTouched[dom_street] || showAllErrors) && Boolean(formErrors[dom_street])}
              helperText={(formTouched[dom_street] || showAllErrors) && formErrors[dom_street]}
              required
              id={dom_street}
              label="Alamat Domisili"
              name={`${pageKey}.${dom_street}`}
              fullWidth
              autoComplete="alamat-domisili"
              variant="standard"
            />
          </Grid>
          <Grid item container xs={12} spacing={2}>
            <DomicileLocation 
              formData={formData}
              pageKey={pageKey}
              showAllErrors={showAllErrors}
              snackbar={snackbar} setSnackbar={setSnackbar}

            />
          </Grid>
          <Grid item xs={12} sx={{marginBottom: '1.5rem'}}>
            <TextField
              label="Kode Pos Domisili"
              type="text"
              fullWidth
              variant="standard"
              value={formValue[dom_po_code]}
              onChange={formData.handleChange}
              onBlur={formData.handleBlur}
              error={(formTouched[dom_po_code] || showAllErrors) && Boolean(formErrors[dom_po_code])}
              helperText={(formTouched[dom_po_code] || showAllErrors) && formErrors[dom_po_code]}
              required
              id={dom_po_code}
              name={`${pageKey}.${dom_po_code}`}
            />
          </Grid>
        </>
        }

        <Grid item xs={12}>
          <TextField
            label="NPWP"
            fullWidth
            autoComplete="npwp"
            variant="standard"
            value={formValue[tax_no]}
            onChange={formData.handleChange}
            onBlur={formData.handleBlur}
            error={(formTouched[tax_no] || showAllErrors) && Boolean(formErrors[tax_no])}
            helperText={(formTouched[tax_no] || showAllErrors) && formErrors[tax_no]}
            id={tax_no}
            name={`${pageKey}.${tax_no}`}
          />
        </Grid>
        <Grid item xs={12}>
          <Box sx={{display: 'flex', flexDirection: 'column'}}>
            <Typography variant="h6" gutterBottom>
              Foto NPWP
            </Typography>
            <Typography variant="h8" gutterBottom>
              (Foto NPWP yang masih berlaku, foto tidak boleh terpotong, ukuran gambar tidak boleh lebih dari 3Mb)
            </Typography>
          </Box>
          <FormUploadFile
            disabled={!formValue[tax_no]}
            customForms={customForms}
            testId={'tax_img'}
            options={{
              accept: {
                'image/*': []
              },
              maxFiles: 1,
              maxSize: 1000000 * 3, // 3mb
              minSize: 100000 // 100 kb
            }}  
            onChange={(newValue) => {
              updateCustomForms('tax_img', newValue)
            }}
          />
          {errorCustomForms[0]?.['tax_img'] && !customForms['tax_img'] && formValue[tax_no] && 
          <Typography variant="subtitle2" gutterBottom component="div" style={{color: 'red'}}>
            <Alert severity="error" sx={{mt: 2}}>{'data kosong'}</Alert>
          </Typography>
          }
        </Grid>
        <Grid item xs={12}>
          <FormAutoComplete 
            label="Bank"
            options={banks}
            value={formValue['bank_code']}
            defaultValue={formValue[bank_name]}
            name={`${pageKey}.bank_code`}
            onChange={(name, val) => {
              if (val === null || val?.length === 0) {
                formData.setFieldValue(`${pageKey}.bank_code`, '');
                formData.setFieldValue(`${pageKey}.${bank_name}`, '');
                return
              }
              formData.setFieldValue(`${pageKey}.${bank_name}`, val?.label);
            }}
            setFieldValue={formData.setFieldValue}
            onBlur={formData.handleBlur}
            error={(formTouched[bank_name] || showAllErrors) && Boolean(formErrors[bank_name])}
            helperText={(formTouched[bank_name] || showAllErrors) && formErrors[bank_name]}
          />
        </Grid>
        <Grid item xs={12}>
          <FormAutoComplete
            disabled={isTempatLahirLoading}
            value={formValue[bank_branch_city + '_code']}
            defaultValue={formValue[bank_branch_city]}
            onChange={(name, val) => {
              formData.setFieldValue(`${pageKey}.${bank_branch_city}`, val.label);
            }}
            name={`${pageKey}.${bank_branch_city}_code`}
            setFieldValue={formData.setFieldValue}
            onBlur={formData.handleBlur}
            error={(formTouched[bank_branch_city] || showAllErrors) && Boolean(formErrors[bank_branch_city])}
            helperText={(formTouched[bank_branch_city] || showAllErrors) && formErrors[bank_branch_city]}
            label="Cabang Bank"
            options={tempatLahirList}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Nomor Rekening"
            type="text"
            fullWidth
            autoComplete="bank_account_no"
            variant="standard"
            value={formValue[bank_account_no]}
            onChange={formData.handleChange}
            onBlur={formData.handleBlur}
            error={(formTouched[bank_account_no] || showAllErrors) && Boolean(formErrors[bank_account_no])}
            helperText={(formTouched[bank_account_no] || showAllErrors) && formErrors[bank_account_no]}
            required
            id={bank_account_no}
            name={`${pageKey}.${bank_account_no}`}
          />
        </Grid>
        <Grid item xs={12}>
          <Box sx={{display: 'flex', flexDirection: 'column'}}>
            <Typography variant="h6" gutterBottom>
              Foto Buku Tabungan*
            </Typography>
            <Typography variant="h8" gutterBottom>
                (Foto halaman pertama buku tabungan, terdapat informasi nama bank, kantor cabang,
                nama pemilik dan nomor rekening)
            </Typography>
          </Box>
          <FormUploadFile
            customForms={customForms}
            testId={'bank_cover_img'}
            options={{
              accept: {
                'image/*': []
              },
              maxFiles: 1,
              maxSize: 1000000 * 3, // 3mb
              minSize: 100000 // 100 kb
            }}   
            onChange={(newValue) => {
              updateCustomForms('bank_cover_img', newValue)
            }}
          />
          {errorCustomForms[0]?.['bank_cover_img'] && !customForms['bank_cover_img'] && 
          <Typography variant="subtitle2" gutterBottom component="div" style={{color: 'red'}}>
            <Alert severity="error" sx={{mt: 2}}>{'data kosong'}</Alert>
          </Typography>
          }
        </Grid>
        <Grid item xs={12}>
          <Box sx={{display: 'flex', flexDirection: 'column'}}>
            <Typography variant="h6" gutterBottom>
              Foto Kartu Tani
            </Typography>
            <Typography variant="h8" gutterBottom>
                (Foto tampak depan kartu tani, yang terdapat informasi nama pemilik dan nomor kartu tani)
            </Typography>
          </Box>
          <FormUploadFile
            customForms={customForms}
            testId={'farmer_card_img'}
            options={{
              accept: {
                'image/*': []
              },
              maxFiles: 1,
              maxSize: 1000000 * 3, // 3mb
              minSize: 100000 // 100 kb
            }}   
            onChange={(newValue) => {
              updateCustomForms('farmer_card_img', newValue)
            }}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
