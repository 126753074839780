import React, { useRef, useState } from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

import DataDiriForm from './DataDiriForm';
import DataLahanForm from './DataLahanForm';
import {
  coreService
} from '../../core/service'
import { useFormik } from 'formik';
import * as yup from 'yup';
import registrationFormBuilder from './registrationFormBuilder';
import AlertDialog from '../../components/utils/AlertDialog';
import { useSearchParams, useNavigate } from 'react-router-dom'
import AnalyticsEventTracker from '../../hooks/useAnalyticsEventTracker';

import {
  registrationFormApi
} from '../../apis'
import { Alert, Backdrop, CircularProgress, Snackbar, StepLabel } from '@mui/material';
import kitaniLogo from '../../assets/brand/kitani.png';

const RegistrationForm = () => {
  const navigate = useNavigate();
  const analytics = AnalyticsEventTracker('Registration Form');
  
  let { formBuilder, validationSchemas } = registrationFormBuilder().buildForm();
  const steps = ['Data Diri', 'Lokasi Lahan.'];
  const stepNameMap = {
    0: 'personal',
    1: 'land'
  }
  // eslint-disable-next-line no-unused-vars
  let [searchParams, setSearchParams] = useSearchParams();
  const [customForms, setCustomForms] = useState({
    'dob': ''
  })
  const [landCoords, setLandCoords] = useState({
    "land_loc_lat": '',
    "land_loc_long": '',
  })
  const [leadId, setLeadId] = useState('')

  const [activeStep, setActiveStep] = React.useState(0);
  const [validation, setValidation] = React.useState(yup.object());
  const [completed, setCompleted] = React.useState({});
  const [currentGps, setCurrentGps] = React.useState(null);
  const [errorMsg, setErrorMsg] = React.useState(''); 
  const [isReady, setIsReady] = React.useState(false);
  const [errorCustomForms, setErrorCustomForms] = React.useState({});
  let errorMsgCustomForms = {}

  const formik = useFormik({
    initialValues: {},
    validationSchema: validation
  });

  // Save values everytime an input is blurred
  const formikHandleBlur = formik.handleBlur;
  formik.handleBlur = (...rest) => {
    formikHandleBlur.apply(this, rest);
    coreService.setObjectItem(`registrationForm-${leadId}:lastValues`, formik.values);
  };

  const [showConfirm, setShowConfirm] = React.useState(false);
  const [showAllErrors, setShowAllErrors] = React.useState(false);

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    coreService.setObjectItem('completedStep', newCompleted)
    setCompleted(newCompleted);
    analytics.eventTracker('Click', `Complete step`);
  };

  const validateCustomForms = (activeStep) => {
    if (typeof activeStep === 'undefined' || activeStep === null) {
      return false
    }
    const steps = {
      0: [
        'idcard_img', 
        'farmer_img', 
        'tax_img', 
        'bank_cover_img',
      ],
      1: [
        'land_letter_img', 
        'land_img'
      ]
    }
    if (formik.values.land.land_owner !== '1') {
      delete steps[1];
    }
    let count = 0
    const currentSteps = steps[activeStep]
    for (let i = 0; i < currentSteps?.length; i += 1) {
      const key = currentSteps[i]      
      if (!errorMsgCustomForms[activeStep]) {
        errorMsgCustomForms[activeStep] = {}
      }

      if (['', null, undefined].indexOf(customForms[key]) === -1) {
        errorMsgCustomForms[activeStep][key] = false
      } else {
        if (!formik.values?.personal?.['tax_no'] && key === 'tax_img') {
          errorMsgCustomForms[activeStep]['tax_img'] = false
        } else {
          count++
          errorMsgCustomForms[activeStep][key] = true
        }
      }
      setErrorCustomForms(errorMsgCustomForms)
    }
    
    return count === 0
  }

  const containerRef = useRef();
  const handleNext = () => {
    formik.validateForm().then(res => {
      const Isvalid = validateCustomForms(activeStep)
      if (Object.keys(res?.[stepNameMap[activeStep]] || {}).length || !Isvalid) {
        setShowAllErrors(true);
        coreService.scrollToErrorElement(containerRef);
        analytics.eventTracker('Click', `Failed next to ${steps[activeStep + 1]} page`);
        return;
      }

      if (activeStep === steps.length-1) {
        setShowConfirm(true);
        return;
      }

      coreService.scrollUp();
      formik.setTouched({});
      setShowAllErrors(false);
      handleComplete()
      coreService.setItem('currentActiveStep', activeStep + 1)
      setActiveStep(activeStep + 1);
      analytics.eventTracker('Click', `Next to ${steps[activeStep + 1]} page`);
    });
  };

  const handleBack = () => {
    coreService.scrollUp();
    formik.setTouched({});
    setShowAllErrors(false);
    coreService.setItem('currentActiveStep', activeStep - 1)
    setActiveStep(activeStep - 1);
    analytics.eventTracker('Click', `Back to ${steps[activeStep - 1]} page`);
  };

  const handleStep = (step) => () => {
    analytics.eventTracker('Click', `Jump to ${steps[step]} page`);
    if (!completed[step]) {
      return
    }
    coreService.setItem('currentActiveStep', step)
    setActiveStep(step);
  };

  const updateCustomForms = (key, newValue) => {
    setCustomForms(customForms => ({
      ...customForms,
      [key]: newValue
    }));
  };

  const [snackbar, setSnackbar] = React.useState({severity: 'error', message: '', open: false});
  const handleCloseSnackbar = () => setSnackbar({...snackbar, open: false});
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const submitForm = async () => {
    const formPayload = {...formik.values};
    const customFormsPayload = {
      'personal': [
        'idcard_img', 
        'farmer_img', 
        'tax_img', 
        'bank_cover_img', 
        'farmer_card_img',
        'dob'
      ],
      'land': [
        'land_letter_img', 
        'land_img'
      ]
    }
    setIsSubmitting(true);
    const formPayloadKeys = Object.keys(formPayload)
    for (let j = 0; j < formPayloadKeys?.length; j += 1) {
      const key = formPayloadKeys[j]
      if (!customFormsPayload[key]) {
        continue
      }

      for (let i = 0; i < customFormsPayload[key]?.length; i += 1) {
        const cfk = customFormsPayload[key][i]
        if (cfk === 'dob') {
          continue
        }

        if (typeof customForms[cfk] === 'undefined' || customForms[cfk]?.length === 0) {
          continue
        }
        let newImg = customForms[cfk][0] 
        newImg = newImg?.file?.split('base64,')
        const file = coreService.b64toBlob(
          newImg[1], customForms[cfk][0]?.type?.includes('image') ? 'image/png' : customForms[cfk][0]?.type, false, key
        )
        let formData = new FormData()
        formData.append('ctx', 'farmerweb')
        formData.append('sub', cfk)
        formData.append('media', file)
        await registrationFormApi.uploadFile(formData)
          .then(data => {
            formPayload[key][cfk] = data?.data?.data?.media
          })
      }
    }
    if (customForms?.['dob']) {
      const dob = new Date(customForms?.['dob'])
      const year = dob.getFullYear()
      const month = dob.getMonth() + 1
      const date = dob.getDate()
      const monthLabel = (month < 10 ? ('0' + month) : month)
      const dateLabel = (date < 10 ? ('0' + date) : date)
      formPayload['personal']['dob'] = (year + '-' + monthLabel + '-' + dateLabel)
    }
    formPayload['land']['land_loc_lat'] = String(landCoords?.land_loc_lat)
    formPayload['land']['land_loc_long'] = String(landCoords?.land_loc_long)
    formPayload['enc_ref_leads_id'] = leadId
    if (formPayload['personal']['bank_branch_city_code']) {
      delete formPayload['personal']['bank_branch_city_code']
    }
    if ([1, '1', '', undefined, null, false].indexOf(formPayload['personal']['dom_flag']) !== -1) {
      formPayload['personal']['dom_street'] = ''
      formPayload['personal']['dom_province_code'] = ''
      formPayload['personal']['dom_province'] = ''
      formPayload['personal']['dom_city_code'] = ''
      formPayload['personal']['dom_city'] = ''
      formPayload['personal']['dom_district_code'] = ''
      formPayload['personal']['dom_district'] = ''
      formPayload['personal']['dom_village_code'] = ''
      formPayload['personal']['dom_village'] = ''
      formPayload['personal']['dom_po_code'] = ''
    }
    if (typeof formPayload['personal']['tax_img'] === 'undefined' || !formPayload['personal']['tax_img']) {
      formPayload['personal']['tax_img'] = ''
    }
    if (typeof formPayload['personal']['farmer_card_img'] === 'undefined'
    || !formPayload['personal']['farmer_card_img']) {
      formPayload['personal']['farmer_card_img'] = ''
    }
    registrationFormApi.submitRegisForm(formPayload)
      .then((res) => {
        coreService.removeItem('currentActiveStep')
        coreService.removeItem('completedStep')
        coreService.removeItem(`registrationForm-${leadId}:lastValues`)
        setActiveStep(activeStep + 1);
      })
      .catch(e => {
        const errorMessage = e?.response?.data?.message || e?.message || 'Maaf terjadi kesalahan.';
        setSnackbar({...snackbar, open: true, message: errorMessage});
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  React.useEffect(() => {
    if (!searchParams.get('c')) {
      navigate("/lead-questionnaire");
      return
    }

    analytics.pageViewTracker();
    const regLink = searchParams.get('c')
    const regDate = searchParams.get('d')
    registrationFormApi.getLeadQuestionnaire(regLink, regDate)
      .catch(error => {
        if (error?.response?.data?.code === 404) {
          navigate('/error')
          return
        }

        setIsReady(true)
        setErrorMsg(error?.response?.data?.message)
      })
      .then(data => {
        if (typeof data === 'undefined') {
          return
        }

        formik.setFieldValue('land.land_owner', data?.land?.land_owner);
        setLeadId(data?.enc_leads_id)
        const lastValues = coreService.getObjectItem(`registrationForm-${data?.enc_leads_id}:lastValues`);
        if (lastValues && !coreService.isEmptyObject(lastValues)) {
          // eslint-disable-next-line react-hooks/exhaustive-deps
          formBuilder = lastValues;
        } else {
          Object.keys(data).forEach((parentKey) => {
            if (formBuilder[parentKey]) {
              Object.entries(data[parentKey]).forEach(([key, value]) => {
                formBuilder[parentKey][key] = value
              })
            }
          })
        }
        
        if (formBuilder['land']['land_area']) {
          delete formBuilder['land']['land_area']
        }
        formik.setValues(formBuilder)
        setIsReady(true)
        if ('geolocation' in navigator) {
          navigator.geolocation.getCurrentPosition((position) => {
            setCurrentGps({
              latitude: position.coords.latitude,
              longitude: position.coords.longitude
            })
          },
          () => {
            navigator.permissions
              .query({ name: "geolocation" })
              .then(function (result) {
                if (result.state === "denied") {
                  console.log('denied')
                }
              });
          }
          );
        }
        let currentActiveStep = Number(coreService.getItem('currentActiveStep')) || 0
        if (!coreService.getItem('currentActiveStep')) {
          currentActiveStep = activeStep
          coreService.setItem('currentActiveStep', activeStep)
        }
        if (coreService.getObjectItem('completedStep')) {
          const completedStep = coreService.getObjectItem('completedStep')
          setCompleted(completedStep)
        }
        setActiveStep(currentActiveStep)
      })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Only activate validation for active form
  React.useEffect(() => {
    setValidation(validation.shape({
      [stepNameMap[activeStep]]: validationSchemas[stepNameMap[activeStep]] || null
    }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep]);

  function getStepContent(step) {
    if (coreService.isEmptyObject(formik.values)) {
      return null
    }
    switch (step) {
    case 0:
      return <DataDiriForm formData={formik} showAllErrors={showAllErrors} 
        updateCustomForms={updateCustomForms} customForms={customForms}
        errorCustomForms={errorCustomForms}
        snackbar={snackbar} setSnackbar={setSnackbar}
      />;
    case 1:
      return <DataLahanForm formData={formik} showAllErrors={showAllErrors} 
        customForms={customForms}
        updateCustomForms={updateCustomForms} 
        errorCustomForms={errorCustomForms}
        setLandCoords={setLandCoords}
        snackbar={snackbar} setSnackbar={setSnackbar}
      />;
                
    default:
      throw new Error('Unknown step');
    }
  }

  return (
    <Container ref={containerRef} component="main" maxWidth="sm" sx={{ mb: 4 }}>
      <AlertDialog 
        content={'Pastikan data-data yang dimasukan sudah benar dan tidak terdapat kesalahan'}
        isShown={showConfirm}
        toggleFn={setShowConfirm}
        title={'Konfirmasi pengiriman data?'}
        yesLabel={'Kirim'}
        noLabel={'Batal'}
        callbackFn={async () => {
          await submitForm()
          analytics.eventTracker('Click', `Submit registration form`);
        }}
      />
      <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
        <Box sx={{width: '100%', display: 'flex', justifyContent: 'center'}}>
          <img style={{maxHeight: '75px', maxWidth: '175px', height:'100%', width:'100%'}} src={kitaniLogo} alt='kitani-header' />
        </Box>
        {activeStep < steps.length &&
        <Typography component="h1" variant="h4" align="center">
            Registration Form
        </Typography>
        }
        {activeStep < steps.length && 
        <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
          {steps.map((label, index) => (
            <Step key={label} completed={completed[index]}>
              {!isReady || errorMsg ? <StepLabel StepIconComponent={() => (<Skeleton width={30} animation='wave' height={30} variant='circular' />)}>{ }</StepLabel>
                : 
                <StepButton color="inherit" onClick={handleStep(index)}>
                  {label}
                </StepButton> 
              }
            </Step>
          ))}
        </Stepper>}
        <React.Fragment>
          {errorMsg ? 
            <React.Fragment>
              <Typography variant="h5" gutterBottom>
                {errorMsg}
              </Typography>
            </React.Fragment> : null
          }
          {activeStep === steps.length ? (
            <React.Fragment>
              <Typography variant="h5" gutterBottom>
                Terimakasih atas registrasi anda.
              </Typography>
              <Typography variant="subtitle1">
                Tim Kitani akan menghubungi anda dalam waktu 2x24 jam.
              </Typography>
            </React.Fragment>
          ) : isReady ? (
            <React.Fragment>
              {isReady ? getStepContent(activeStep) : null}
              
              {isReady && !errorMsg && 
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                {activeStep !== 0 && (
                  <Button disabled={!isReady} onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                    Kembali
                  </Button>
                )}

                <Button
                  disabled={!isReady}
                  variant="contained"
                  onClick={handleNext}
                  sx={{ mt: 3, ml: 1 }}
                >
                  {activeStep === steps.length - 1 ? 'Kirim' : 'Lanjutkan'}
                </Button>
              </Box>
              }
            </React.Fragment>
          ) : (<>
            <Skeleton animation="wave" width="100%" height={30} />
            <Skeleton animation="wave" width="100%" height={50} />
            <Skeleton animation="wave" width="100%" height={30} />
            <Skeleton animation="wave" width="100%" height={50} />
          </>)}
        </React.Fragment>
      </Paper>

      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity || 'error'} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isSubmitting}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
}

export default RegistrationForm;