import React from 'react'
import Container from '@mui/material/Container';
import Footer from '../footer'

const Layout = ({ children }) => {
  return (
    <Container component="main" maxWidth="false">
      {children}
      <Footer />
    </Container>
  )
}

export default Layout