import { Autocomplete, FormControl, FormHelperText, LinearProgress, TextField } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect } from 'react';

function FormAutocomplete(props) {
  const { 
    label,
    name, 
    options = [{ label: 'Please select', value: '' }],
    setFieldValue,
    helperText, 
    error,
    value,
    defaultValue,
    onBlur,
    disabled,
    isLoading,
    onChange = () => {},
  } = props;

  const [searchInput, setSearchInput] = React.useState('');
  const [controlledValue, setControlledValue] = React.useState({});
  useEffect(() => {
    if (options.length > 0) {
      const textInput = options.find(i => i.value === value);
      if (textInput) {
        setControlledValue(textInput);
        return setSearchInput(textInput.label);
      }
      setControlledValue({});
      setSearchInput('');
    }
  }, [options, value]);
  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth error={error}>
        <Autocomplete
          isOptionEqualToValue={(opt, value) => { return opt.value === value.value }}
          disablePortal
          getOptionLabel={(option) => option.label || ''}
          id={`combo-box-${name}`}
          sx={{ width: 'auto' }}
          disabled={disabled}
          label={label}
          defaultValue={defaultValue}
          value={controlledValue}
          inputValue={searchInput}
          onInputChange={(e,v) => { e && setSearchInput(v)}}
          onChange={(event, val) => {
            setFieldValue(name, val !== null ? val.value : value);
            onChange(name, val);
          }}
          name={name}
          onBlur={onBlur}
          options={options}
          renderInput={(params) => <TextField {...params} label={label} />}
        />
        {isLoading && <LinearProgress />}
        <FormHelperText>{helperText}</FormHelperText>
      </FormControl>
    </Box>
  );
}

export default FormAutocomplete;