import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { kitaniApi } from '../../apis';
import FormAutocomplete from '../../components/inputs/FormAutoComplete';
import { InputAdornment } from '@mui/material';
import { Box } from '@mui/system';

export default function DataDiriForm({ formData, showAllErrors, pageKey = 'personal', inTesting = false,
  snackbar,
  setSnackbar
}) {
  // Store field name string into variable
  const formValue = formData.values[pageKey],
    formTouched = formData.touched[pageKey] || {},
    formErrors = formData.errors[pageKey] || {},
    full_name = 'full_name',
    msisdn = 'msisdn',
    street = 'street',
    province = 'province',
    city = 'city',
    district = 'district',
    village = 'village',
    po_code = 'po_code';

  const firstElementRef = React.useRef(null);
  const [isLoadingArea, setIsLoadingArea] = React.useState({ province: false, city: false, district: false, village: false });
  const [provOptions, setProvOptions] = React.useState([]);
  const areaFieldNames = [
    `${pageKey}.${province}`,
    `${pageKey}.${city}`,
    `${pageKey}.${district}`,
    `${pageKey}.${village}`
  ];

  const resetAreaFields = (fields = []) => {
    fields.forEach(field => {
      formData.setFieldValue(field, '')
      formData.setFieldValue(field + '-code', '')
    });
  };

  function loadProvince() {
    kitaniApi.get(`/location/area?at=p`).then(res => {
      const { data: { data } } = res;
      const options = data.map(item => ({
        value: item.ac,
        id: item.ac,
        label: item.an
      }));
      setProvOptions(options);
    }).catch(e => {
      setSnackbar({ ...snackbar, open: true, message: "Gagal Mendapatkan Data Provinsi" });
    }).finally(() => setIsLoadingArea({ ...isLoadingArea, province: false }));
  }
  React.useEffect(() => {
    setIsLoadingArea({ ...isLoadingArea, province: true });
    loadProvince();
    firstElementRef.current.focus();
    // eslint-disable-next-line
  }, []);

  // Handle when province change
  const [cityOptions, setCityOptions] = React.useState([]);
  function loadCity() {
    if (!formValue[province] && !inTesting) {
      return;
    }
    setIsLoadingArea({ ...isLoadingArea, city: true });
    kitaniApi.get(`/location/area?at=c&pac=${formValue[province + '-code']}`).then(res => {
      const { data: { data } } = res;
      const options = data.map(item => ({
        value: item.ac,
        id: item.ac,
        label: item.an
      }));
      setCityOptions(options);
    }).catch(e => {
      setSnackbar({ ...snackbar, open: true, message: "Gagal Mendapatkan Data Kota" });
    }).finally(() => setIsLoadingArea({ ...isLoadingArea, city: false }));
  }
  React.useEffect(() => {
    loadCity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValue[province]]);

  // Handle when city change
  const [districtOptions, setDistrictOptions] = React.useState([]);
  function loadDistrict() {
    if (!formValue[city] && !inTesting) {
      return;
    }
    setIsLoadingArea({ ...isLoadingArea, district: true });
    kitaniApi.get(`/location/area?at=d&pac=${formValue[city + '-code']}`).then(res => {
      const { data: { data } } = res;
      const options = data.map(item => ({
        value: item.ac,
        id: item.ac,
        label: item.an
      }));
      setDistrictOptions(options);
    }).catch(e => {
      setSnackbar({ ...snackbar, open: true, message: "Gagal Mendapatkan Data Kecamatan" });      
    }).finally(() => setIsLoadingArea({ ...isLoadingArea, district: false }));
  }
  React.useEffect(() => {
    loadDistrict();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValue[city]]);

  // Handle when district change
  const [villageOptions, setVillageOptions] = React.useState([]);
  function loadVillage() {
    if (!formValue[district] && !inTesting) {
      return;
    }
    setIsLoadingArea({ ...isLoadingArea, village: true });
    kitaniApi.get(`/location/area?at=v&pac=${formValue[district + '-code']}`).then(res => {
      const { data: { data } } = res;
      const options = data.map(item => ({
        value: item.ac,
        id: item.ac,
        label: item.an
      }));
      setVillageOptions(options);
    }).catch(e => {
      setSnackbar({ ...snackbar, open: true, message: "Gagal Mendapatkan Data Kelurahan" });      
    }).finally(() => setIsLoadingArea({ ...isLoadingArea, village: false }));
  }
  React.useEffect(() => {
    loadVillage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValue[district]]);

  React.useEffect(() => {
    if (inTesting) {
      loadProvince();
      loadCity();
      loadDistrict();
      loadVillage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <React.Fragment>
      <Typography variant="h6">
        Data Diri
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            inputRef={firstElementRef}
            value={formValue[full_name]}
            onChange={formData.handleChange}
            onBlur={formData.handleBlur}
            error={(formTouched[full_name] || showAllErrors) && Boolean(formErrors[full_name])}
            helperText={(formTouched[full_name] || showAllErrors) && formErrors[full_name]}
            required
            id={full_name}
            name={`${pageKey}.${full_name}`}
            label="Nama Lengkap Sesuai KTP"
            fullWidth
            autoComplete="given-name"
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            InputProps={{ startAdornment: <InputAdornment position="start"><Box sx={{ color: 'text.primary' }}>+62</Box></InputAdornment> }}
            value={formValue[msisdn]}
            onChange={formData.handleChange}
            onBlur={(e) => {
              formData.handleBlur(e);
              const newVal = e?.target?.value?.split(' ').join('');
              formData.setFieldValue(`${pageKey}.${msisdn}`, newVal);
            }}
            error={(formTouched[msisdn] || showAllErrors) && Boolean(formErrors[msisdn])}
            helperText={(formTouched[msisdn] || showAllErrors) && formErrors[msisdn]}
            required
            id={msisdn}
            name={`${pageKey}.${msisdn}`}
            label="Nomer Whatsapp"
            fullWidth
            autoComplete="phone-number"
            variant="standard"
          />
        </Grid>
      </Grid>

      <Typography marginTop={"2rem"} variant="h6">Alamat sesuai KTP</Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            value={formValue[street]}
            onChange={formData.handleChange}
            onBlur={formData.handleBlur}
            error={(formTouched[street] || showAllErrors) && Boolean(formErrors[street])}
            helperText={(formTouched[street] || showAllErrors) && formErrors[street]}
            required
            id={street}
            name={`${pageKey}.${street}`}
            label="Jalan"
            fullWidth
            autoComplete="street-line"
            variant="standard"
          />
        </Grid>
        <Grid position='relative' item xs={12}>
          <FormAutocomplete
            disabled={isLoadingArea[province]}
            isLoading={isLoadingArea[province]}
            value={formValue[province + '-code']}
            name={`${pageKey}.${province}-code`}
            setFieldValue={formData.setFieldValue}
            onChange={(name, val) => {
              resetAreaFields(areaFieldNames.slice(1));
              formData.setFieldValue(`${pageKey}.${province}`, val?.label || '');
            }}
            onBlur={formData.handleBlur}
            error={(formTouched[province] || showAllErrors) && Boolean(formErrors[province])}
            helperText={(formTouched[province] || showAllErrors) && formErrors[province]}
            label="Provinsi"
            options={provOptions}
          />
        </Grid>
        <Grid item xs={12}>
          <FormAutocomplete
            disabled={isLoadingArea[city] || !formValue[province]}
            isLoading={isLoadingArea[city]}
            value={formValue[city + '-code']}
            name={`${pageKey}.${city}-code`}
            setFieldValue={formData.setFieldValue}
            onChange={(name, val) => {
              resetAreaFields(areaFieldNames.slice(2));
              formData.setFieldValue(`${pageKey}.${city}`, val?.label || '');
            }}
            onBlur={formData.handleBlur}
            error={(formTouched[city] || showAllErrors) && Boolean(formErrors[city])}
            helperText={(formTouched[city] || showAllErrors) && formErrors[city]}
            label="Kabupaten/Kota"
            options={cityOptions}
          />
        </Grid>
        <Grid item xs={12}>
          <FormAutocomplete
            disabled={isLoadingArea[district] || !formValue[city]}
            isLoading={isLoadingArea[district]}
            value={formValue[district + '-code']}
            name={`${pageKey}.${district}-code`}
            setFieldValue={formData.setFieldValue}
            onChange={(name, val) => {
              resetAreaFields(areaFieldNames.slice(3));
              formData.setFieldValue(`${pageKey}.${district}`, val?.label || '');
            }}
            onBlur={formData.handleBlur}
            error={(formTouched[district] || showAllErrors) && Boolean(formErrors[district])}
            helperText={(formTouched[district] || showAllErrors) && formErrors[district]}
            label="Kecamatan"
            options={districtOptions}
          />
        </Grid>
        <Grid item xs={12}>
          <FormAutocomplete
            disabled={isLoadingArea[village] || !formValue[district]}
            isLoading={isLoadingArea[village]}
            value={formValue[village + '-code']}
            name={`${pageKey}.${village}-code`}
            setFieldValue={formData.setFieldValue}
            onChange={(name, val) => {
              formData.setFieldValue(`${pageKey}.${village}`, val?.label || '');
            }}
            onBlur={formData.handleBlur}
            error={(formTouched[village] || showAllErrors) && Boolean(formErrors[village])}
            helperText={(formTouched[village] || showAllErrors) && formErrors[village]}
            label="Kelurahan/Desa"
            options={villageOptions}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id={po_code}
            onBlur={formData.handleBlur}
            name={`${pageKey}.${po_code}`}
            label="Kode Pos"
            fullWidth
            autoComplete="shipping postal-code"
            variant="standard"
            onChange={formData.handleChange}
            error={(formTouched[po_code] || showAllErrors) && Boolean(formErrors[po_code])}
            helperText={(formTouched[po_code] || showAllErrors) && formErrors[po_code]}
            value={formValue[po_code]}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
