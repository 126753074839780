import { Box, CircularProgress, Snackbar } from "@mui/material";
import Typography from "@mui/material/Typography";
import { convertFromRaw, EditorState } from "draft-js";
import * as React from "react";
import { useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { questionnaireApi } from "../../apis";
import MuiAlert from "@mui/material/Alert";
import "./style.css";

export default function AddressForm() {
  const [isLoading, setIsLoading] = useState(false);
  const [isNoData, setIsNoData] = useState(false);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [snackbar, setSnackbar] = useState({
    severity: "error",
    message: "",
    open: false,
  });

  React.useEffect(() => {
    getLQWording();
  }, []);

  const getLQWording = async () => {
    setIsLoading(true);
    try {
      const response = await questionnaireApi.getQuestionnaireWording();
      if (response.data.length > 0) {
        setIsNoData(false);
        setEditorState(
          EditorState.createWithContent(
            convertFromRaw({
              blocks: response.data,
              entityMap: {},
            })
          )
        );
      } else {
        setIsNoData(true);
      }
    } catch (e) {
      const errorMessage =
        e?.response?.data?.message || e?.message || "Maaf terjadi kesalahan.";
      setSnackbar({ ...snackbar, open: true, message: errorMessage });
      setIsNoData(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseSnackbar = () => setSnackbar({ ...snackbar, open: false });

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  return (
    <React.Fragment>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity || "error"}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
      {isLoading ? (
        <Box display={"flex"} justifyContent="center" alignItems={"center"}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Editor
            editorState={editorState}
            toolbarClassName="preview-toolbar"
            readOnly={true}
          />

          {!editorState ||
            (isNoData && (
              <>
                <Typography variant="h6" gutterBottom>
                  Langkah Awal Kemitraan
                </Typography>
                <Box>
                  <Typography gutterBottom variant="body1">
                    Kitani ingin mengajak para petani untuk bekerjasama menanam
                    buah, sayur, dan komoditas dengan memberi bantuan bibit,
                    penyuluhan, teknologi, dan kepastian penjualan melalui
                    sistem bagi hasil yang adil dan transparan.
                  </Typography>
                  <Typography gutterBottom variant="body1">
                    Silakan isi pertanyaan berikut agar kami dapat mengenal Anda
                    lebih dekat untuk menjalin kemitraan dengan Kitani.
                  </Typography>
                </Box>
              </>
            ))}
        </>
      )}
    </React.Fragment>
  );
}
