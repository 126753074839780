import ReactGA from "react-ga4";

const AnalyticsEventTracker = (category="Questionnaire") => {
  const googleAnalytics = ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID);
  // Interactions
  const eventTracker = (action = "Click", label = "Clicking button") => {
    ReactGA.event({category, action, label});
  }

  // Non interactions
  const pageViewTracker = (path = window.location.pathname) => {
    ReactGA.send('pageview', path);
  }
  return { googleAnalytics, eventTracker, pageViewTracker };
}
export default AnalyticsEventTracker;
