import * as React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import FormRadioButton from '../../components/inputs/FormRadioButton';
import { TextField } from '@mui/material';
import FormDropdown from '../../components/inputs/FormDropdown';

const radioInputOptions = [{ value: 'Setiap Hari' }, { value: 'Seminggu Sekali' }, { value: 'Sebulan Sekali' }];
export default function DataLainnyaForm({ formData, showAllErrors, configs: { others = [] }, pageKey = 'others' }) {
  const formValue = formData.values[pageKey],
    formTouched = formData.touched[pageKey] || {},
    formErrors = formData.errors[pageKey] || {},
    other_key = 'other_key',
    other_value = 'other_value',
    prefilledOthersData = others.map((item) => ({
      name: item.key,
      type: item.type,
      options: item?.items?.map(opt => ({ value: opt }))
    }));
  const firstElementRef = React.useRef(null);
  const dummyRef = React.useRef(null);
  React.useEffect(() => {
    firstElementRef.current.focus();
  }, []);

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Data Lainnya
      </Typography>
      <Typography gutterBottom variant='h6'>Seberapa sering anda menggunakan aplikasi dibawah ini?</Typography>

      <Grid marginTop={'0.5rem'} container spacing={3}>
        {prefilledOthersData.map((otherItem, key) => (
          <Grid key={`${otherItem?.name}-${key}`} item xs={12}>
            {otherItem.type === 'select' ?
              (<FormDropdown
                inputRef={(key === 0 && firstElementRef) || dummyRef}
                value={formValue[key]?.[other_value]}
                name={`${pageKey}.${key}.${other_value}`}
                onChange={formData.handleChange}
                onBlur={formData.handleBlur}
                error={(formTouched[key]?.[other_value] || showAllErrors) && Boolean(formErrors[key]?.[other_value])}
                helperText={(formTouched[key]?.[other_value] || showAllErrors) && formErrors[key]?.[other_value]}
                label={otherItem?.name}
                options={otherItem?.options || []}
              />) :
              (<FormRadioButton
                inputRef={(key === 0 && firstElementRef) || dummyRef}
                value={formValue[key]?.[other_value]}
                name={`${pageKey}.${key}.${other_value}`}
                onChange={formData.handleChange}
                onBlur={formData.handleBlur}
                error={(formTouched[key]?.[other_value] || showAllErrors) && Boolean(formErrors[key]?.[other_value])}
                helperText={(formTouched[key]?.[other_value] || showAllErrors) && formErrors[key]?.[other_value]}
                label={otherItem?.name}
                options={otherItem?.options || []}
              />)}
          </Grid>
        ))}

        <Grid item xs={12}>
          <TextField
            value={formValue[prefilledOthersData.length]?.[other_key]}
            name={`${pageKey}.${prefilledOthersData.length}.${other_key}`}
            onChange={formData.handleChange}
            onBlur={formData.handleBlur}
            error={(formTouched[prefilledOthersData.length]?.[other_key] || showAllErrors) && Boolean(formErrors[prefilledOthersData.length]?.[other_key])}
            helperText={(formTouched[prefilledOthersData.length]?.[other_key] || showAllErrors) && formErrors[prefilledOthersData.length]?.[other_key]} 
            id="aplikasiLainName"
            label="Aplikasi lainnya sebutkan"
            fullWidth variant="standard"
          />
        </Grid>
        <Grid item xs={12}>
          <FormRadioButton
            value={formValue[prefilledOthersData.length]?.[other_value]}
            name={`${pageKey}.${prefilledOthersData.length}.${other_value}`}
            onChange={formData.handleChange}
            onBlur={formData.handleBlur}
            error={(formTouched[prefilledOthersData.length]?.[other_value] || showAllErrors) && Boolean(formErrors[prefilledOthersData.length]?.[other_value])}
            helperText={(formTouched[prefilledOthersData.length]?.[other_value] || showAllErrors) && formErrors[prefilledOthersData.length]?.[other_value]} 
            label={''}
            options={radioInputOptions}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
