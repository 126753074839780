import axios from 'axios';

export const kitaniApi = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json',
    'x-api-key': process.env.REACT_APP_X_API_KEY
  }
});

// 10s
kitaniApi.defaults.timeout = 10000;

const getQuestionnaireConfigs = () => {
  const path = '/questionnaire/config-fields';
  return kitaniApi.get(path).then(data => data?.data);
};

const getQuestionnaireWording = () => {
  const path = '/farmer-web/content/lead-questionnaire';
  return kitaniApi.get(path).then(data => data?.data);
};

const submitForm = (formValues) => {
  const path = '/questionnaire';
  const { userAgent } = navigator;
  return kitaniApi.post(path, {...formValues, userAgent: userAgent});
};

const submitRegisForm = (formValues) => {
  const path = '/registration';
  return kitaniApi.post(path, formValues);
};

const uploadFile = (payload) => {
  const path = '/upload';
  return kitaniApi.post(path, payload);
};

const getLeadQuestionnaire = (regLink, regDate) => {
  let path = `/leads`;
  if (regLink) {
    path = path + `?c=${regLink}`
  }
  if (regDate) {
    path = path + `&d=${regDate}`
  }
  return kitaniApi.get(path).then(data => data?.data?.data);
};

export const questionnaireApi = { getQuestionnaireConfigs, submitForm, getQuestionnaireWording };
export const registrationFormApi = { getLeadQuestionnaire, submitRegisForm, uploadFile };
export const getAppConfig = () => {
  return axios.get(process.env.REACT_APP_API_URL + 'cfg/app', {
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': process.env.REACT_APP_X_API_KEY_FARMER_APP
    }}
  ).then(res => res.data);
}