import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import FormRadioButton from '../../components/inputs/FormRadioButton';

export default function DataKomoditasForm({formData, showAllErrors, configs, pageKey = 'commodity'}) {
  // Store field name string into variable
  const formValue = formData.values[pageKey],
    formTouched = formData.touched[pageKey] || {},
    formErrors = formData.errors[pageKey] || {},
    last_harvest = 'last_harvest',
    listed_com = 'listed_com',
    unlisted_com = 'unlisted_com',
    listed_com_duration = 'listed_com_duration',
    { listed_commodities: {
      commodities = []
    } = {} } = configs;
  const firstElementRef = React.useRef(null);
  React.useEffect(() => {
    firstElementRef.current.focus();
  }, []);
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Data Komoditas
      </Typography>

      <Typography gutterBottom variant='h6'>Apakah pernah menanam komoditas2 ini?</Typography>
      <Grid container spacing={3}>
        {commodities.map((commodity, key) => {
          const {name: item, durations = []} = commodity;
          const commodityOptions = durations.map(item => ({ value: item})) || [{value: 'Tidak pernah'}, {value: '0-1 tahun'}, {value: '1-3 tahun'}, {value: ">3 tahun", label: 'Lebih dari 3 tahun'}];
          return (<Grid key={`${item}-${key}`} item xs={12}>
            <FormRadioButton
              labelStyle={{fontSize: '1.2rem', fontWeight: 'bold'}}
              inputRef={key === 0 && firstElementRef}
              value={formValue[listed_com][key][listed_com_duration]}
              name={`${pageKey}.${listed_com}[${key}].${listed_com_duration}`}
              onChange={formData.handleChange}
              onBlur={formData.handleBlur}
              error={(formTouched[listed_com]?.[key]?.[listed_com_duration] || showAllErrors) && Boolean(formErrors[listed_com]?.[key]?.[listed_com_duration])}
              helperText={(formTouched[listed_com]?.[key]?.[listed_com_duration] || showAllErrors) && formErrors[listed_com]?.[key]?.[listed_com_duration]}
              label={item}
              options={commodityOptions}
            />
          </Grid>
          )})}
        <Grid item xs={12}>
          <TextField
            value={formValue[unlisted_com]}
            name={`${pageKey}.${unlisted_com}`}
            onChange={formData.handleChange}
            onBlur={formData.handleBlur}
            error={(formTouched[unlisted_com] || showAllErrors) && Boolean(formErrors[unlisted_com])}
            helperText={(formTouched[unlisted_com] || showAllErrors) && formErrors[unlisted_com]}
            id="otherCommudity"
            label="Komuditas lainnya sebutkan"
            fullWidth variant="standard"
          />
        </Grid>
        <Grid item xs={12}>
          <FormRadioButton 
            value={formValue[last_harvest]}
            name={`${pageKey}.${last_harvest}`}
            onChange={formData.handleChange}
            onBlur={formData.handleBlur}
            error={(formTouched[last_harvest] || showAllErrors) && Boolean(formErrors[last_harvest])}
            helperText={(formTouched[last_harvest] || showAllErrors) && formErrors[last_harvest]}
            label="Berapa hasil panen terkahir untuk komoditas tersebut?"
            options={[{value: '<2 ton'}, {value: '2-5 ton'}, {value: '> 5 ton'}]}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
