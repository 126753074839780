export const FormData = (banks) => {
  return {
    'personal': {
      'full_name': '',
      'msisdn': '',
      'gender': 'Laki-Laki',
      'pob': '',
      'idcard_no': '',
      'street': '',
      'province': '',
      'city': '',
      'district': '',
      'village': '',
      'po_code': '',
      'tax_no': '',
      'bank_name': '',
      'bank_account_no': ''
    },
    'land': {
      'land_status': 'Girik',
      'land_area_num': '',
      'land_topology': 'Datar',
      'land_irrigation': 'Kurang dari 1km',
      'land_access': 'Roda 4',
      'land_province': '',
      'land_city': '',
      'land_district': '',
      'land_village': '',
      'land_owner': '0'
    }
  }
}