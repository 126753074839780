import { FormData } from './form-data';
import * as yup from 'yup';

function registrationFormBuilder() {
  // Initiate registration form structure, validation config and values
  const buildForm = () => {
    let formBuilder = FormData(),
      defaultRequiredMessage = 'Data tidak boleh kosong';

    // Validator builder
    const validationSchemas = {
      "personal": yup.object().shape({
        "full_name": yup.string().required(defaultRequiredMessage),
        "msisdn": yup.string().required(defaultRequiredMessage).matches(
          /^[0-9]{11,15}$/,
          "Nomor harus berupa 11 sampai 15 digit angka"
        ),
        "gender": yup.string().required(defaultRequiredMessage),
        'pob': yup.string().required(defaultRequiredMessage),
        'idcard_no': yup.string().required(defaultRequiredMessage).matches(
          /^[0-9]{16}$/,
          "NIK harus berupa 16 digit angka"
        ),
        'street': yup.string().required(defaultRequiredMessage),
        "province": yup.string().required(defaultRequiredMessage),
        "city": yup.string().required(defaultRequiredMessage),
        "district": yup.string().required(defaultRequiredMessage),
        "village": yup.string().required(defaultRequiredMessage),
        'po_code': yup.string().matches(
          /^[0-9]{5}$/, "Kode pos harus berupa 5 digit angka"
        ).required(defaultRequiredMessage),
        'bank_name': yup.string().required(defaultRequiredMessage),
        'bank_account_no': yup.string().required(defaultRequiredMessage),
        "dom_flag": yup.number().required(defaultRequiredMessage),
        "dom_street": yup.string().when('dom_flag', {
          is: 0,
          then: yup.string().required(defaultRequiredMessage)
        }),
        "dom_province": yup.string().when('dom_flag', {
          is: 0,
          then: yup.string().required(defaultRequiredMessage)
        }),
        "dom_city": yup.string().when('dom_flag', {
          is: 0,
          then: yup.string().required(defaultRequiredMessage)
        }),
        "dom_district": yup.string().when('dom_flag', {
          is: 0,
          then: yup.string().required(defaultRequiredMessage)
        }),
        "dom_village": yup.string().when('dom_flag', {
          is: 0,
          then: yup.string().required(defaultRequiredMessage)
        }),
        "dom_po_code": yup.string().when('dom_flag', {
          is: 0,
          then: yup.string().matches(
            /^[0-9]{5}$/, "Kode pos domisili harus berupa 5 digit angka"
          ).required(defaultRequiredMessage)
        }),
        'bank_branch_city': yup.string().required(defaultRequiredMessage),
      }),
      "land": yup.object().shape({
        'land_status': yup.string().nullable(),
        'land_area_num': yup.string().when('land_owner', {
          is: '1',
          then: yup.string().required(defaultRequiredMessage)
        }),
        'land_topology': yup.string().nullable(),
        'land_irrigation': yup.string().nullable(),
        'land_access': yup.string().nullable(),
        'land_province': yup.string().when('land_owner', {
          is: '1',
          then: yup.string().required(defaultRequiredMessage)
        }),
        'land_city': yup.string().when('land_owner', {
          is: '1',
          then: yup.string().required(defaultRequiredMessage)
        }),
        'land_district': yup.string().when('land_owner', {
          is: '1',
          then: yup.string().required(defaultRequiredMessage)
        }),
        'land_village': yup.string().when('land_owner', {
          is: '1',
          then: yup.string().required(defaultRequiredMessage)
        })
      })
    };

    return { validationSchemas, formBuilder };
  };

  return { buildForm };
}

export default registrationFormBuilder;