import formConfig from './formConfig.json';
import * as yup from 'yup';
import { questionnaireApi } from '../../apis';
import { coreService } from '../../core/service';

function questionnaireFormBuilder() {
  // Initiate questionnaire form structure, validation config and values
  const buildForm = () => {
    const afterConfigLoaded = (configs) => {
      const formStructures = formConfig || {},
        formPages = Object.keys(formStructures),
        defaultRequiredMessage = 'Data tidak boleh kosong';
      let formBuilder = {};

      const populateForm = (page, fields) => {
        const tempFormBuilder = formBuilder[page];
        if (typeof fields === 'object' && fields.hasOwnProperty('length')) {
          fields.forEach((field) => {
            tempFormBuilder[field] = '';
          });
          return;
        }

        if (page === 'commodity') {
          const { listed_commodities: { commodities = [{
            "name": "Melon",
            "durations": [
              "0-1 tahun",
              "1-3 tahun",
              ">3 tahun"
            ]
          }] } = {} } = configs;
          tempFormBuilder['listed_com'] = commodities.map(item => ({
            listed_com_name: item.name,
            listed_com_duration: ''
          }));
          tempFormBuilder['unlisted_com'] = '';
          tempFormBuilder['last_harvest'] = '';
        }

        if (page === 'others') {
          const { others = {
            "key": "Facebook",
            "type": "select",
            "required": true,
            "items": [
              "Pernah",
              "Tidak Pernah"
            ]
          } } = configs;
          others.forEach(item => tempFormBuilder.push({
            other_key: item.key,
            other_value: ''
          }));
          tempFormBuilder.push({
            other_key: '',
            other_value: ''
          });
        }
      };

      const lastValues = coreService.getObjectItem('questionnaire:lastValues');
      if (lastValues && !coreService.isEmptyObject(lastValues)) {
        formBuilder = lastValues;
      }
      else {
        formPages.forEach((page) => {
          if (!formBuilder[page]) {
            formBuilder[page] = page === 'others' ? [] : {};
          }
          populateForm(page, formStructures[page]);
        });
      }

      // Validator builder

      // Make all listed commodities mandatory
      const commoditiesSchema = {
        "listed_com": yup.array().of(yup.object().shape({
          listed_com_name: yup.string().required(defaultRequiredMessage),
          listed_com_duration: yup.string().required(defaultRequiredMessage)
        })),
        "unlisted_com": yup.lazy(() => yup.string().when('last_harvest', {
          is: (lastHarvest) => lastHarvest && lastHarvest.length > 0,
          then: yup.string().required(defaultRequiredMessage)
        })),
        "last_harvest": yup.lazy(() => yup.string().when('unlisted_com', {
          is: (unlisted) => unlisted && unlisted.length > 0,
          then: yup.string().required(defaultRequiredMessage)
        }))
      };

      const validationSchema = {
        "personal": yup.object().shape({
          "full_name": yup.string().required(defaultRequiredMessage),
          "msisdn": yup.string().required(defaultRequiredMessage).test(
            'waNumber',
            "Nomor Whatsapp harus berupa 9 sampai 12 digit angka",
            (value) => {
              return value?.split(' ').join('').match(/^[0-9]{9,12}$/);
            }
          ).test('startWith628',
            'Nomor Whatsapp harus berawalan 628',
            (value) => {
              const trimmedValue = value?.split(' ').join('');
              return trimmedValue?.length && trimmedValue[0] === '8';
            }),
          "street": yup.string().required(defaultRequiredMessage),
          "province": yup.string().required(defaultRequiredMessage),
          "city": yup.string().required(defaultRequiredMessage),
          "district": yup.string().required(defaultRequiredMessage),
          "village": yup.string().required(defaultRequiredMessage),
          "po_code": yup.string().matches(
            /^[0-9]{5}$/, "Kode pos harus berupa 5 digit angka"
          ).required(defaultRequiredMessage)
        }),
        "land": yup.object().shape({
          "land_owner": yup.number().required(defaultRequiredMessage),
          "land_status": yup.string().when('land_owner', {
            is: 1,
            then: yup.string().required(defaultRequiredMessage)
          }),
          "land_area": yup.string().when('land_owner', {
            is: 1,
            then: yup.string().required(defaultRequiredMessage)
          }),
          "land_topology": yup.string().when('land_owner', {
            is: 1,
            then: yup.string().required(defaultRequiredMessage)
          }),
          "land_irrigation": yup.string().when('land_owner', {
            is: 1,
            then: yup.string().required(defaultRequiredMessage)
          }),
          "land_access": yup.string().when('land_owner', {
            is: 1,
            then: yup.string().required(defaultRequiredMessage)
          })
        }),
        "commodity": yup.object().shape(commoditiesSchema),
        "area_info": yup.object().shape({
          "qty_farmer": yup.string().required(defaultRequiredMessage),
          "land_potential": yup.string().required(defaultRequiredMessage)
        }),
        "others": yup.array().of(yup.object().shape({
          "other_key": yup.lazy(() => yup.string().when('other_value', {
            is: (otherValue) => otherValue && otherValue.length > 0,
            then: yup.string().required(defaultRequiredMessage)
          })),
          "other_value": yup.lazy(() => yup.string().when('other_key', {
            is: (otherKey) => otherKey && otherKey.length > 0,
            then: yup.string().required(defaultRequiredMessage)
          }))
        }))
      };

      return { validationSchema, formBuilder, configs };
    }

    return questionnaireApi.getQuestionnaireConfigs()
      .then((data) => afterConfigLoaded(data?.data));
  };

  return { buildForm };
}

export default questionnaireFormBuilder;