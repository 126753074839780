import React from 'react';
import { FormControl, FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup } from '@mui/material';

function FormRadioButton({ label, options, name, onChange, value, onBlur, error, helperText, inputRef, disabled, labelStyle = {} }) {
  const dummyRef = React.useRef(null);
  return (
    <FormControl error={error}>
      <FormLabel id={`id-${name}`} sx={{ ...labelStyle }}>{label}</FormLabel>
      <RadioGroup
        aria-labelledby={name}
        name={name}
        onChange={onChange}
        value={value}
        onBlur={onBlur}
      >
        {options.map(({ value: optValue, label: optLabel }, key) => (
          <FormControlLabel disabled={disabled}
            inputRef={(key === 0 && inputRef) || dummyRef} key={optValue} value={optValue} control={<Radio />} label={optLabel || optValue} />
        ))}
      </RadioGroup>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
}

export default FormRadioButton;