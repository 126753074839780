import * as React from 'react';
import Grid from '@mui/material/Grid';
import FormUploadFile from '../../components/inputs/FormUploadFile';
import Typography from '@mui/material/Typography';
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup
} from 'react-leaflet'
import './register-form.css'
import { kitaniApi } from '../../apis';
import FormAutoComplete from '../../components/inputs/FormAutoComplete';
import * as Nominatim from "nominatim-browser";
import { Alert, Box, TextField } from '@mui/material';

export default function DataLahanForm({
  formData, showAllErrors, pageKey = 'land', updateCustomForms, customForms,
  errorCustomForms, setLandCoords, 
  snackbar,
  setSnackbar
}) {
  // default location
  const defaultLocation = [
    -6.208244453430968, 106.84256426450652
  ]
  // Store field name string into variable
  const formValue = formData.values[pageKey],
    formTouched = formData.touched[pageKey] || {},
    formErrors = formData.errors[pageKey] || {},
    land_status = 'land_status',
    land_area_num = 'land_area_num',
    land_topology = 'land_topology',
    land_irrigation = 'land_irrigation',
    land_access = 'land_access',
    land_province = 'land_province',
    land_city = 'land_city',
    land_district = 'land_district',
    land_village = 'land_village';

  const [isLoadingArea, setIsLoadingArea] = React.useState(false);
  const [provOptions, setProvOptions] = React.useState([]);
  const areaFieldNames = [
    `${pageKey}.${land_province}`,
    `${pageKey}.${land_city}`,
    `${pageKey}.${land_district}`,
    `${pageKey}.${land_village}`
  ];

  const resetAreaFields = (fields = []) => {
    fields.forEach(field => {
      formData.setFieldValue(field, '')
      formData.setFieldValue(field + '_code', '')
    });
  };

  React.useEffect(() => {
    setIsLoadingArea(true);
    kitaniApi.get(`/location/area?at=p`).then(res => {
      const { data: { data } } = res;
      const options = data.map(item => ({
        value: item.ac,
        label: item.an
      }));
      setProvOptions(options);
    }).catch(e => {
      setSnackbar({ ...snackbar, open: true, message: "Gagal Mendapatkan Data Provinsi" });
    }).finally(() => setIsLoadingArea(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [cityOptions, setCityOptions] = React.useState([]);

  React.useEffect(() => {
    if (!formValue[land_province]) {
      return;
    }
    setIsLoadingArea(true);
    kitaniApi.get(`/location/area?at=c&pac=${formValue[land_province + '_code']}`).then(res => {
      const { data: { data } } = res;
      const options = data.map(item => ({
        value: item.ac,
        label: item.an
      }));
      setCityOptions(options);
    }).catch(e => {
      setSnackbar({ ...snackbar, open: true, message: "Gagal Mendapatkan Data Kota" });
    }).finally(() => setIsLoadingArea(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValue[land_province]]);

  const [districtOptions, setDistrictOptions] = React.useState([]);
  React.useEffect(() => {
    if (!formValue[land_city]) {
      return;
    }
    setIsLoadingArea(true);
    kitaniApi.get(`/location/area?at=d&pac=${formValue[land_city + '_code']}`).then(res => {
      const { data: { data } } = res;
      const options = data.map(item => ({
        value: item.ac,
        label: item.an
      }));
      setDistrictOptions(options);
    }).catch(e => {
      setSnackbar({ ...snackbar, open: true, message: "Gagal Mendapatkan Data Kecamatan" });
    }).finally(() => setIsLoadingArea(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValue[land_city]]);

  const [villageOptions, setVillageOptions] = React.useState([]);

  React.useEffect(() => {
    if (!formValue[land_district]) {
      return;
    }
    setIsLoadingArea(true);
    kitaniApi.get(`/location/area?at=v&pac=${formValue[land_district + '_code']}`).then(res => {
      const { data: { data } } = res;
      const options = data.map(item => ({
        value: item.ac,
        label: item.an
      }));
      setVillageOptions(options);
    }).catch(e => {
      setSnackbar({ ...snackbar, open: true, message: "Gagal Mendapatkan Data Kelurahan" });
    }).finally(() => setIsLoadingArea(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValue[land_district]]);

  React.useEffect(() => {
    if (!formValue[land_village] || villageOptions?.length === 0) {
      return;
    }

    setLocationNotFound(false)
    const selectedVillage = villageOptions.find((village) => village.label === formValue[land_village])?.label
    const selectedProvince = provOptions.find((prov) => prov.label === formValue[land_province])?.label
    Nominatim.geocode({
      city: selectedVillage,
      state: selectedProvince,
      countrycodes: 'ID',
      country: 'Indonesia'
    }).then((results) => {
      const result = results[0]
      if (!result) {
        setPosition(defaultLocation)
        setLocationNotFound(true)
        return
      }
      const lat = result?.lat
      const long = result?.lon
      setLandCoords({
        "land_loc_lat": lat,
        "land_loc_long": long,
      })
      setPosition([lat, long])
      if (map) {
        map.flyTo([lat, long])
      }
      setDraggable(true)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValue[land_village], villageOptions]);

  const [position, setPosition] = React.useState(defaultLocation)
  const [map, setMap] = React.useState(null)
  const [draggable, setDraggable] = React.useState(false)
  const [locationNotFound, setLocationNotFound] = React.useState(false)

  function LocationMarker() {
    const markerRef = React.useRef(null)
    const eventHandlers = React.useMemo(
      () => ({
        dragend() {
          const marker = markerRef.current
          if (marker != null) {
            const latlngMarker = marker.getLatLng()
            setLandCoords({
              "land_loc_lat": latlngMarker?.lat,
              "land_loc_long": latlngMarker?.lng,
            })
            setPosition(latlngMarker)
          }
        },
      }),
      [],
    )
    return position === null ? null : (
      <Marker
        draggable={draggable}
        position={position}
        eventHandlers={eventHandlers}
        ref={markerRef}
      >
        <Popup>You are here</Popup>
      </Marker>
    )
  }

  const showSelectedOptionLabel = (options, value) => {
    return options?.find(option => option?.value === value)?.label ?? ''
  }

  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom>
            Data Lahan
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h7" gutterBottom>
            {'Status kepemilikan lahan: '} {showSelectedOptionLabel([{ label: "Tanah Girik", value: "Girik" }, { value: "AJB", label: "Akta Jual Beli" }, { value: "SHM", label: "Sertifikat Hak Milik" }],
              formValue[land_status])
            }
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            Surat Legalitas Lahan
          </Typography>
          <Typography variant="subtitle2" gutterBottom>
            (Lampirkan bukti kepemilikan lahan sesuai status diatas)
          </Typography>
          <FormUploadFile
            customForms={customForms}
            testId={'land_letter_img'}
            options={{
              accept: {
                'application/pdf': ['.pdf']
              },
              maxFiles: 1,
              maxSize: 5000000, // 5mb
              minSize: 100000 // 100 kb
            }}
            onChange={(newValue) => {
              updateCustomForms('land_letter_img', newValue)
            }}
          />
          {errorCustomForms[1]?.['land_letter_img'] && !customForms['land_letter_img'] &&
            <Typography variant="subtitle2" gutterBottom component="div" style={{ color: 'red' }}>
              <Alert severity="error" sx={{ mt: 2 }}>{'data kosong'}</Alert>
            </Typography>
          }
        </Grid>
        <Grid item xs={12}>
          <TextField
            value={formValue[land_area_num]}
            onChange={formData.handleChange}
            onBlur={formData.handleBlur}
            error={(formTouched[land_area_num] || showAllErrors) && Boolean(formErrors[land_area_num])}
            helperText={(formTouched[land_area_num] || showAllErrors) && formErrors[land_area_num]}
            required
            id={land_area_num}
            name={`${pageKey}.${land_area_num}`}
            label="Luas Lahannya (meter persegi)"
            type="text"
            fullWidth
            autoComplete="luas-lahan"
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sx={{ display: formData.values?.land?.land_owner === '1' ? 'block' : 'none' }}>
          <Typography variant="h7" gutterBottom>
            {'Kondisi lahannya: '} {formValue[land_topology]}
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ display: formData.values?.land?.land_owner === '1' ? 'block' : 'none' }}>
          <Typography variant="h7" gutterBottom>
            {'Akses irigasi nya: '} {showSelectedOptionLabel(
              [{ value: "<1 km", label: "Kurang dari 1km" }, { value: "1-4 km", label: "1km - 4km" }, { value: ">4 km", label: "Lebih dari 4km" }],
              formValue[land_irrigation])
            }
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ display: formData.values?.land?.land_owner === '1' ? 'block' : 'none' }}>
          <Typography variant="h7" gutterBottom>
            {'Akses ke lahan dapat dilalui kendaraan: '} {formValue[land_access]}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom>
            Lokasi Lahan
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormAutoComplete
            disabled={isLoadingArea}
            label="Provinsi"
            options={provOptions}
            value={formValue[land_province + '_code']}
            name={`${pageKey}.${land_province}_code`}
            setFieldValue={formData.setFieldValue}
            defaultValue={formValue[land_province]}
            onChange={(name, val) => {
              resetAreaFields(areaFieldNames.slice(1));
              formData.setFieldValue(`${pageKey}.${land_province}`, val.label);
            }}
            onBlur={formData.handleBlur}
            error={(formTouched[land_province] || showAllErrors) && Boolean(formErrors[land_province])}
            helperText={(formTouched[land_province] || showAllErrors) && formErrors[land_province]}
          />
        </Grid>
        <Grid item xs={12}>
          <FormAutoComplete
            disabled={isLoadingArea || !formValue[land_province]}
            label="Kabupaten/Kota"
            options={cityOptions}
            value={formValue[land_city + '_code']}
            name={`${pageKey}.${land_city}_code`}
            setFieldValue={formData.setFieldValue}
            defaultValue={formValue[land_city]}
            onBlur={formData.handleBlur}
            onChange={(name, val) => {
              resetAreaFields(areaFieldNames.slice(2));
              formData.setFieldValue(`${pageKey}.${land_city}`, val.label);
            }}
            error={(formTouched[land_city] || showAllErrors) && Boolean(formErrors[land_city])}
            helperText={(formTouched[land_city] || showAllErrors) && formErrors[land_city]}
          />
        </Grid>
        <Grid item xs={12}>
          <FormAutoComplete
            disabled={isLoadingArea || !formValue[land_city]}
            label="Kecamatan"
            options={districtOptions}
            value={formValue[land_district + '_code']}
            name={`${pageKey}.${land_district}_code`}
            defaultValue={formValue[land_district]}
            onChange={(name, val) => {
              resetAreaFields(areaFieldNames.slice(3));
              formData.setFieldValue(`${pageKey}.${land_district}`, val.label);
            }}
            setFieldValue={formData.setFieldValue}
            onBlur={formData.handleBlur}
            error={(formTouched[land_district] || showAllErrors) && Boolean(formErrors[land_district])}
            helperText={(formTouched[land_district] || showAllErrors) && formErrors[land_district]}
          />
        </Grid>
        <Grid item xs={12}>
          <FormAutoComplete
            disabled={isLoadingArea || !formValue[land_district]}
            label="Kelurahan/Desa"
            options={villageOptions}
            value={formValue[land_village + '_code']}
            name={`${pageKey}.${land_village}_code`}
            setFieldValue={formData.setFieldValue}
            defaultValue={formValue[land_district]}
            onBlur={formData.handleBlur}
            onChange={(name, val) => {
              formData.setFieldValue(`${pageKey}.${land_village}`, val.label);
            }}
            error={(formTouched[land_village] || showAllErrors) && Boolean(formErrors[land_village])}
            helperText={(formTouched[land_village] || showAllErrors) && formErrors[land_village]}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom>
            Pin Lokasi Lahan
          </Typography>
          {locationNotFound && <h3>{'Location not found'}</h3>}
        </Grid>
        <div className="map-box" style={{ display: 'flex', flexDirection: 'column' }}>
          <MapContainer center={position} zoom={15} ref={setMap}>
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <LocationMarker />
          </MapContainer>
        </div>
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant="h6" gutterBottom>
              Foto Lahan
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
              (Foto lahan yang nanti akan dijadikan lokasi penanaman, pastikan terlihat sebagian
              besar lahan terlihat dengan jelas)
            </Typography>
          </Box>
          <FormUploadFile
            customForms={customForms}
            testId={'land_img'}
            options={{
              accept: {
                'image/*': []
              },
              maxFiles: 1,
              maxSize: 1000000 * 3, // 3mb
              minSize: 100000 // 100 kb
            }}
            onChange={(newValue) => {
              updateCustomForms('land_img', newValue)
            }}
          />
          {errorCustomForms[1]?.['land_img'] && !customForms['land_img'] &&
            <Typography variant="subtitle2" gutterBottom component="div" style={{ color: 'red' }}>
              <Alert severity="error" sx={{ mt: 2 }}>{'data kosong'}</Alert>
            </Typography>
          }
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
