import React, { Suspense, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import Layout from './components/layout'
import Error from './pages/error'
import LeadQuestionnaire from './pages/lead-questionnaire'
import RegistrationForm from './pages/register-form'
import { getAppConfig } from './apis'
import { coreService } from './core/service';

export default function App() {

  useEffect(() => {
    getAppConfig()
      .then(res => {
        coreService.setObjectItem('appConfig', res.data)
      })
  }, [])

  return (
    <React.Fragment>
      <Suspense fallback={<div>Loading ...</div>}>
        <Router>
          <Routes>
            <Route exact path="/lead-questionnaire" element={
              <Layout>
                <LeadQuestionnaire />
              </Layout>} 
            />
            <Route exact path="/registration-form" element={
              <Layout>
                <RegistrationForm />
              </Layout>
            } />
            <Route exact path="/error" element={<Error />} />
            <Route exact path="/" element={<Navigate to="/lead-questionnaire" />} />
            <Route
              path="*"
              element={<Error />}
            />
          </Routes>
        </Router>
      </Suspense>
    </React.Fragment>
  );
}