import { Grid } from '@mui/material';
import React from 'react';
import { kitaniApi } from '../../apis';
import FormAutocomplete from '../../components/inputs/FormAutoComplete';

const DomicileLocation = ({ formData, pageKey, showAllErrors, snackbar,
  setSnackbar }) => {
  const formValue = formData.values[pageKey],
    formTouched = formData.touched[pageKey] || {},
    formErrors = formData.errors[pageKey] || {},
    dom_province = 'dom_province',
    dom_city = 'dom_city',
    dom_district = 'dom_district',
    dom_village = 'dom_village';

  const [isLoadingArea, setIsLoadingArea] = React.useState(false);
  const [provOptions, setProvOptions] = React.useState([]);
  const areaFieldNames = [
    `${pageKey}.${dom_province}`,
    `${pageKey}.${dom_city}`,
    `${pageKey}.${dom_district}`,
    `${pageKey}.${dom_village}`
  ];

  const resetAreaFields = (fields = []) => {
    fields.forEach(field => {
      formData.setFieldValue(field, '')
      formData.setFieldValue(field + '_code', '')
    });
  };

  React.useEffect(() => {
    setIsLoadingArea(true);
    kitaniApi.get(`/location/area?at=p`).then(res => {
      const { data: { data } } = res;
      const options = data.map(item => ({
        value: item.ac,
        label: item.an
      }));
      setProvOptions(options);
    }).catch(e => {
      setSnackbar({ ...snackbar, open: true, message: "Gagal Mendapatkan Data Provinsi" });
    }).finally(() => setIsLoadingArea(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [cityOptions, setCityOptions] = React.useState([]);

  React.useEffect(() => {
    async function init() {
      if (!formValue[dom_province]) {
        return;
      }
      setIsLoadingArea(true);
      if (!formValue[dom_province + '_code']) {
        await kitaniApi.get(`/location/area?at=p`).then(res => {
          const { data: { data } } = res;
          const selectedProvince = data.find((item) => item.an?.includes(formValue[dom_province]))
          formValue[dom_province + '_code'] = selectedProvince?.ac
          const options = data.map(item => ({
            value: item.ac,
            label: item.an
          }));
          setProvOptions(options);
        })
      }
      kitaniApi.get(`/location/area?at=c&pac=${formValue[dom_province + '_code']}`).then(res => {
        const { data: { data } } = res;
        const options = data.map(item => ({
          value: item.ac,
          label: item.an
        }));
        setCityOptions(options);
      }).catch(e => {
        setSnackbar({ ...snackbar, open: true, message: "Gagal Mendapatkan Data Kota" });
      }).finally(() => setIsLoadingArea(false));
    }
    init()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValue[dom_province]]);

  const [districtOptions, setDistrictOptions] = React.useState([]);
  React.useEffect(() => {
    async function init() {
      if (!formValue[dom_city]) {
        return;
      }
      setIsLoadingArea(true);
      if (!formValue[dom_city + '_code']) {
        await kitaniApi.get(`/location/area?at=c&pan=${formValue[dom_province]}`).then(res => {
          const { data: { data } } = res;
          const selectedCity = data.find((item) => item.an?.includes(formValue[dom_city]))
          formValue[dom_city + '_code'] = selectedCity?.ac
        })
      }
      kitaniApi.get(`/location/area?at=d&pac=${formValue[dom_city + '_code']}`).then(res => {
        const { data: { data } } = res;
        const options = data.map(item => ({
          value: item.ac,
          label: item.an
        }));
        setDistrictOptions(options);
      }).catch(e => {
        setSnackbar({ ...snackbar, open: true, message: "Gagal Mendapatkan Data Kecamatan" });
      }).finally(() => setIsLoadingArea(false));
    }
    init()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValue[dom_city]]);

  const [villageOptions, setVillageOptions] = React.useState([]);

  React.useEffect(() => {
    async function init() {
      if (!formValue[dom_district]) {
        return;
      }
      setIsLoadingArea(true);
      if (!formValue[dom_district + '_code']) {
        await kitaniApi.get(`/location/area?at=d&pan=${formValue[dom_city]}`).then(res => {
          const { data: { data } } = res;
          const selectedDistrict = data.find((item) => item.an?.includes(formValue[dom_district]))
          formValue[dom_district + '_code'] = selectedDistrict?.ac
        })
      }
      kitaniApi.get(`/location/area?at=v&pac=${formValue[dom_district + '_code']}`).then(res => {
        const { data: { data } } = res;
        const options = data.map(item => ({
          value: item.ac,
          label: item.an
        }));
        setVillageOptions(options);
      }).catch(e => {
        setSnackbar({ ...snackbar, open: true, message: "Gagal Mendapatkan Data Kelurahan" });
      }).finally(() => setIsLoadingArea(false));
    }
    init()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValue[dom_district]]);

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <FormAutocomplete
          disabled={isLoadingArea}
          label="Provinsi Domisili"
          options={provOptions}
          value={formValue[dom_province + '_code']}
          defaultValue={formValue[dom_province]}
          onChange={(name, val) => {
            resetAreaFields(areaFieldNames.slice(1));
            formData.setFieldValue(`${pageKey}.${dom_province}`, val.label);
          }}
          name={`${pageKey}.${dom_province}_code`}
          setFieldValue={formData.setFieldValue}
          onBlur={formData.handleBlur}
          error={(formTouched[dom_province] || showAllErrors) && Boolean(formErrors[dom_province])}
          helperText={(formTouched[dom_province] || showAllErrors) && formErrors[dom_province]}
        />
      </Grid>
      <Grid item xs={12}>
        <FormAutocomplete
          disabled={isLoadingArea || !formValue[dom_province]}
          label="Kabupaten/Kota Domisili"
          options={cityOptions}
          value={formValue[dom_city + '_code']}
          name={`${pageKey}.${dom_city}_code`}
          defaultValue={formValue[dom_city]}
          onChange={(name, val) => {
            resetAreaFields(areaFieldNames.slice(2));
            formData.setFieldValue(`${pageKey}.${dom_city}`, val.label);
          }}
          setFieldValue={formData.setFieldValue}
          onBlur={formData.handleBlur}
          error={(formTouched[dom_city] || showAllErrors) && Boolean(formErrors[dom_city])}
          helperText={(formTouched[dom_city] || showAllErrors) && formErrors[dom_city]}
        />
      </Grid>
      <Grid item xs={12}>
        <FormAutocomplete
          disabled={isLoadingArea || !formValue[dom_city]}
          label="Kecamatan Domisili"
          options={districtOptions}
          value={formValue[dom_district + '_code']}
          defaultValue={formValue[dom_district]}
          name={`${pageKey}.${dom_district}_code`}
          onChange={(name, val) => {
            resetAreaFields(areaFieldNames.slice(3));
            formData.setFieldValue(`${pageKey}.${dom_district}`, val.label);
          }}
          setFieldValue={formData.setFieldValue}
          onBlur={formData.handleBlur}
          error={(formTouched[dom_district] || showAllErrors) && Boolean(formErrors[dom_district])}
          helperText={(formTouched[dom_district] || showAllErrors) && formErrors[dom_district]}
        />
      </Grid>
      <Grid item xs={12}>
        <FormAutocomplete
          disabled={isLoadingArea || !formValue[dom_district]}
          label="Kelurahan/Desa Domisili"
          options={villageOptions}
          value={formValue[dom_village + '_code']}
          defaultValue={formValue[dom_village]}
          name={`${pageKey}.${dom_village}_code`}
          onChange={(name, val) => {
            formData.setFieldValue(`${pageKey}.${dom_village}`, val.label);
          }}
          setFieldValue={formData.setFieldValue}
          onBlur={formData.handleBlur}
          error={(formTouched[dom_village] || showAllErrors) && Boolean(formErrors[dom_village])}
          helperText={(formTouched[dom_village] || showAllErrors) && formErrors[dom_village]}
        />
      </Grid>
    </React.Fragment>
  )

}

export default DomicileLocation