import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function AlertDialog(props) {
  const { isShown, toggleFn, title, content, yesLabel, noLabel, callbackFn } = props;

  return (
    <div>
      <Dialog
        open={isShown}
        onClose={() => {
          toggleFn(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        title={title}
        content={content}
      >
        <DialogTitle id="alert-dialog-title">
          {title || 'Apakah data kuesioner sudah benar?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            toggleFn(false);
          }}>{noLabel || `No`}</Button>
          <Button onClick={() => {
            toggleFn(false);
            callbackFn();
          }} autoFocus>
            {yesLabel || `Yes`}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
