import * as React from 'react';
import Grid from '@mui/material/Grid';
import { FormControl, Select, InputLabel, MenuItem, Box, Typography, FormHelperText } from '@mui/material';
import FormRadioButton from '../../components/inputs/FormRadioButton';

export default function DataLahanForm({ formData, showAllErrors, pageKey = 'land' }) {
  // Store field name string into variable
  const formValue = formData.values[pageKey],
    formTouched = formData.touched[pageKey] || {},
    formErrors = formData.errors[pageKey] || {},
    land_owner = 'land_owner',
    land_status = 'land_status',
    land_area = 'land_area',
    land_topology = 'land_topology',
    land_irrigation = 'land_irrigation',
    land_access = 'land_access';
  const firstElementRef = React.useRef(null);

  React.useEffect(() => {
    firstElementRef.current.focus();
  }, []);
  return (
    <React.Fragment>
      <Typography variant='h6' gutterBottom>Data Lahan</Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth error={(formTouched[land_owner] || showAllErrors) && Boolean(formErrors[land_owner])}>
              <InputLabel id={land_owner + '-label'}>Apakah memiliki lahan untuk ditanami ?</InputLabel>
              <Select
                inputRef={firstElementRef}
                name={`${pageKey}.land_owner`}
                value={formValue?.[land_owner]}
                onChange={formData.handleChange}
                onBlur={formData.handleBlur}
                labelId={land_owner + '-label'}
                id={land_owner}
                label="Apakah memiliki lahan untuk ditanami ?"
              >
                <MenuItem value={1}>Ya</MenuItem>
                <MenuItem value={0}>Tidak</MenuItem>
              </Select>
              <FormHelperText>{(formTouched[land_owner] || showAllErrors) && formErrors[land_owner]}</FormHelperText>
            </FormControl>
          </Box>
        </Grid>

        {[0, '0', '', undefined, null, false].indexOf(formValue[land_owner]) === -1 && <>
          <Grid item xs={12}>
            <FormRadioButton
              name={`${pageKey}.${land_status}`}
              value={formValue[land_status]}
              onChange={formData.handleChange}
              onBlur={formData.handleBlur}
              error={(formTouched[land_status] || showAllErrors) && Boolean(formErrors[land_status])}
              helperText={(formTouched[land_status] || showAllErrors) && formErrors[land_status]} 
              label="Status kepemilikan lahan ?"
              options={[{ label: "Tanah Girik", value: "Girik" }, { value: "AJB" }, { value: "SHM" }]} />
          </Grid>
          <Grid item xs={12}>
            <FormRadioButton
              name={`${pageKey}.${land_area}`}
              value={formValue[land_area]}
              onChange={formData.handleChange}
              onBlur={formData.handleBlur}
              error={(formTouched[land_area] || showAllErrors) && Boolean(formErrors[land_area])}
              helperText={(formTouched[land_area] || showAllErrors) && formErrors[land_area]} 
              label="Berapa luas lahan nya ?"
              options={[{ value: "<1 ha", label: "Kurang dari 1 Hektar" }, { value: "1-5 ha", label: "1 - 5 Hektar" }, { value: ">5 ha", label: "Lebih dari 5 Hektar" }]} />
          </Grid>
          <Grid item xs={12}>
            <FormRadioButton
              name={`${pageKey}.${land_topology}`}
              value={formValue[land_topology]}
              onChange={formData.handleChange}
              onBlur={formData.handleBlur}
              error={(formTouched[land_topology] || showAllErrors) && Boolean(formErrors[land_topology])}
              helperText={(formTouched[land_topology] || showAllErrors) && formErrors[land_topology]} 
              label="Bagaimana kondisi lahan nya ?"
              options={[{ value: "Datar" }, { value: "Perbukitan" }, { value: "Terasering" }]} />
          </Grid>
          <Grid item xs={12}>
            <FormRadioButton
              name={`${pageKey}.${land_irrigation}`}
              value={formValue[land_irrigation]}
              onChange={formData.handleChange}
              onBlur={formData.handleBlur}
              error={(formTouched[land_irrigation] || showAllErrors) && Boolean(formErrors[land_irrigation])}
              helperText={(formTouched[land_irrigation] || showAllErrors) && formErrors[land_irrigation]} 
              label="Berapa jauh akses irigasi nya ?"
              options={[{ value: "<1 km", label: "Kurang dari 1km" }, { value: "1-4 km", label: "1km - 4km" }, { value: ">4 km", label: "Lebih dari 4km" }]} />
          </Grid>
          <Grid item xs={12}>
            <FormRadioButton
              name={`${pageKey}.${land_access}`}
              value={formValue[land_access]}
              onChange={formData.handleChange}
              onBlur={formData.handleBlur}
              error={(formTouched[land_access] || showAllErrors) && Boolean(formErrors[land_access])}
              helperText={(formTouched[land_access] || showAllErrors) && formErrors[land_access]} 
              label="Apakah akses ke lahan dapat dilalui kendaraan?"
              options={[{ value: "Roda 4" }, { value: "Roda 2" }]} />
          </Grid>
        </>}
      </Grid>
    </React.Fragment>
  );
}
