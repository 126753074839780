import * as React from 'react';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

export default function FormDatePicker({label, inputFormat, formName, onChange, value, error}) {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Stack spacing={3}>
        <DesktopDatePicker
          label={label}
          name={formName}
          inputFormat={inputFormat}
          value={value}
          onChange={onChange}
          renderInput={(params) => <TextField {...params} error={error} />}
        />
      </Stack>
    </LocalizationProvider>
  );
}
